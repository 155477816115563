import {https} from "../config/index.js";

export const getAllMedia = async () => {
    const {data = []} = await https.get(`v2/medias`);
    return data;
};

export const uploadFile = async (formData) => {
    const {data} = await https.post(`/v2/media/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    });
    return data;
};
