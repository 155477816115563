import React, {useState, useEffect} from 'react'
import CardContent from './CardContent/CardContent'
import s from './CardBody.module.scss'
import exMark from '../../../media/main/backet/letters.svg'
import cart from '../../../media/main/shopping-card2.svg'
import card from '../../../media/main/card.svg'
import smallTrack from '../../../media/main/smallTrack.svg'
import cube from '../../../media/main/order/cube.svg'
import {addToCart} from '../../../store/reducers/ShoppingReducer';
import {useDispatch} from 'react-redux'

const CardBody = ({data}) => {
  const dispatch = useDispatch()

  const [quantity, setCount] = useState(0);

  if (data) {
    return (
        <div className={s.flex}>
          <div className={s.flexItem}>
            <p className={s.preTitle}>Товар</p>
            <h1 className={s.title}>{data.name}</h1>

            <CardContent data={data} />
          </div>
          <div className={s.flexItem}>
            {data.price && (
                <>
                  <p className={s.price}>
                    <span>Цена</span>
                    <span>{data.price}₽</span>
                  </p>
                  <p className={s.discount}>
                    <span>Скидка</span>
                    <span>-{data.discount}₽</span>
                  </p>
                </>
            )}
            <div className={s.amount}>
              <div>
                <img src={exMark} alt="" />
                <div className={s.flexDirection}>
                  <p className={s.text}>Количество</p>
                  <div className={s.amountRow}>
                    <button onClick={() => setCount(quantity - 1)}>-</button>
                    <input onChange={e => setCount(parseInt(e.target.value))} value={quantity} className={s.amount}/>
                    <button onClick={() => setCount(quantity + 1)}>+</button>
                  </div>
                </div>
              </div>
              <div className={s.avatar}>M</div>
            </div>
            <button className={s.button} onClick={() =>
                dispatch(addToCart({id: data.id, title: data.name, img: data.image, price: data.price, quantity: quantity}))
            }>
              <img src={cart} alt="Иконка коррзины" />
              <span>Добавить в корзину</span>
            </button>
            <div className={s.wrapper}>
              <img src={smallTrack} alt="Машина" />
              <span>Доставка - уточняйте у консультантов</span>
            </div>
            <div className={s.wrapper}>
              <img src={cube} alt="Машина" />
              <span>Самовывоз из пункта выдачи фирменного магазина </span>
            </div>
            <div className={`${s.wrapper} ${s.noMargin}`}>
              <img src={card} alt="Машина" />
              <span>Возможность оплаты картой рассрочки </span>
            </div>
          </div>
        </div>
    )
  }

  return null;
}

export default CardBody
