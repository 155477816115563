import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getCategories,
  getSubCategories,
} from "../../../store/async/asyncCategories";
import "./Carousel.css";

import a from "../../../media/main/categories/6.png";
import b from "../../../media/main/categories/9.png";
import c from "../../../media/main/categories/11.png";
import d from "../../../media/main/categories/13.png";
import e from "../../../media/main/categories/18.png";
import f from "../../../media/main/categories/30.png";
import g from "../../../media/main/categories/31.png";
import h from "../../../media/main/categories/35.png";

import { useTranslation } from "react-i18next";
import {IMAGE_FALLBACK} from "../../../utils/index.js";
import BaseImage from "../../BaseImage/BaseImage.jsx";

export default function ProductCarousel() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const categories = useSelector((store) => store.categories);
  const { language } = useSelector((store) => store.language);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getSubCategories());
  }, []);

  useEffect(() => {
    dispatch(getCategories(language));
  }, [language]);

  const required_names = [
    "Кабели и провода монтажные",
    "Кабели силовые",
    "Кабели и провода связи",
    "Кабели управления и передачи данных",
    "Провода автотракторные",
    "Провода бортовые облученные",
    "Радиочастотные кабели",
    "Вилки и шнуры армированные",
    "communication cables and wires",
    "mounting cables and wires",
    "control and data cables",
    "power cables",
    "automotive wires",
    "wires for industrial blasting",
    "RF cables",
    "plugs and cords reinforced",
    "кабелі і правады мантажныя",
    "кабелі і правады сувязі",
    "кабелі сілавыя",
    "кабелі кіравання і перадачы даных",
    "правады аўтатрактарныя",
    "правады бартавыя апрамененыя",
    "радыёчастотныя кабелі",
    "відэльцы і шнуры армаваныя",
  ];

  const activeCategories = [];

  if (categories.data)
    categories.data.forEach((d, idx) => {
      const temp_d = { ...d };
      let flag = false;

      required_names.forEach((name) => {
        if (name.toLowerCase() === d.name.toLowerCase()) {
          flag = true;
          return;
        }
      });

      if (d.name === "Кабели силовые".toLowerCase()) {
        temp_d.name = "Кабели силовые и контрольные";
      }

      if (flag) activeCategories.push(temp_d);
    });

  const generatePath = (str) => {
    if (
      str.toLowerCase() === "кабели и провода монтажные" ||
      str.toLowerCase() === "mounting cables and wires" ||
      str.toLowerCase() === "кабелі і правады мантажныя"
    )
      return e;
    if (
      str.toLowerCase() === "кабели и провода связи" ||
      str.toLowerCase() === "communication cables and wires" ||
      str.toLowerCase() === "кабелі і правады сувязі"
    )
      return d;
    if (
      str.toLowerCase() === "кабели управления и передачи данных" ||
      str.toLowerCase() === "control and data cables" ||
      str.toLowerCase() === "кабелі сілавыя"
    )
      return b;
    if (
      str.toLowerCase() === "кабели силовые" ||
      str.toLowerCase() === "power cables" ||
      str.toLowerCase() === "кабелі кіравання і перадачы даных"
    )
      return a;
    if (
      str.toLowerCase() === "провода автотракторные" ||
      str.toLowerCase() === "automotive wires" ||
      str.toLowerCase() === "правады аўтатрактарныя"
    )
      return g;
    if (
      str.toLowerCase() === "провода бортовые облученные" ||
      str.toLowerCase() === "wires for industrial blasting" ||
      str.toLowerCase() === "правады бартавыя апрамененыя"
    )
      return f;
    if (
      str.toLowerCase() === "радиочастотные кабели" ||
      str.toLowerCase() === "rf cables" ||
      str.toLowerCase() === "радыёчастотныя кабелі"
    )
      return c;
    if (
      str.toLowerCase() === "вилки и шнуры армированные" ||
      str.toLowerCase() === "plugs and cords reinforced" ||
      str.toLowerCase() === "відэльцы і шнуры армаваныя"
    )
      return h;
  };

  const generateClass = (str) => {
    if (
      str.toLowerCase() === "кабели и провода монтажные" ||
      str.toLowerCase() === "mounting cables and wires" ||
      str.toLowerCase() === "кабелі і правады мантажныя"
    )
      return "1";
    if (
      str.toLowerCase() === "кабели и провода связи" ||
      str.toLowerCase() === "communication cables and wires" ||
      str.toLowerCase() === "кабелі і правады сувязі"
    )
      return "1";
    if (
      str.toLowerCase() === "кабели управления и передачи данных" ||
      str.toLowerCase() === "control and data cables" ||
      str.toLowerCase() === "кабелі сілавыя"
    )
      return "2";
    if (
      str.toLowerCase() === "кабели силовые" ||
      str.toLowerCase() === "power cables" ||
      str.toLowerCase() === "кабелі кіравання і перадачы даных"
    )
      return "3";
    if (
      str.toLowerCase() === "провода автотракторные" ||
      str.toLowerCase() === "automotive wires" ||
      str.toLowerCase() === "правады аўтатрактарныя"
    )
      return "4";
    if (
      str.toLowerCase() === "провода бортовые облученные" ||
      str.toLowerCase() === "wires for industrial blasting" ||
      str.toLowerCase() === "правады бартавыя апрамененыя"
    )
      return "5";
    if (
      str.toLowerCase() === "радиочастотные кабели" ||
      str.toLowerCase() === "rf cables" ||
      str.toLowerCase() === "радыёчастотныя кабелі"
    )
      return "6";
    if (
      str.toLowerCase() === "вилки и шнуры армированные" ||
      str.toLowerCase() === "plugs and cords reinforced" ||
      str.toLowerCase() === "відэльцы і шнуры армаваныя"
    )
      return "7";
  };

  return (
    <>
      {categories.data ? (
        <div className="product-carousel-container">
          <div className="commonLabels">
            <p className="product-title">{t("Заг Продукция")}</p>
          </div>
          <div className="g-1 product-carousel-row modify">
            {activeCategories.map((data) => (
              <div key={data.id} className="product-carousel-col modify">
                <div
                  className="custom-link"
                  onClick={() => navigate("/products?categoryId=" + data.id)}
                >
                  <div className="product-carousel-card">
                    <figure className="fig">
                      <div className="back">
                        <div className="clip">
                          <BaseImage className={`img ${"img_mod_" + generateClass(data.name)}`} alt={data.name} src={generatePath(data.name)} fallbackSrc={IMAGE_FALLBACK.product} />
                        </div>
                      </div>
                    </figure>
                    <div className="product-carousel-card-body">
                      <p>
                        <span className="card-title modify">
                          {data.name.split("")[0].toUpperCase() +
                            data.name
                              .split("")
                              .slice(1, data.name.split("").length)
                              .join("")}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="catalogButton">
            <Link
              className="custom-button-product"
              variant="danger"
              to={`/products`}
            >
              {t("Весь ассортимент")}
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
