import React, {useState} from 'react'
import {Link, useSearchParams} from 'react-router-dom'
import {addToCart} from '../../../store/reducers/ShoppingReducer';
import s from './MainTopic.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {getProducts} from '../../../store/async/asyncCategories'
import PaginationCatalogue from './Pagination/Pagination'
import Filter from '../Filter/Filter'
import provod from '../../../media/main/default.png'
import scrollIntoView from 'scroll-into-view'
import BaseImage from "../../../components/BaseImage/BaseImage.jsx";
import {getImageSrc} from "../../../utils/index.js";

const MainTopic = ({activeCategory, activeIndustry, activePage, setActivePage}) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(true)
  const {data} = useSelector(store => store.products)
  const {showProducts} = useSelector(store => store.categories)
  const [activePages, setActivePages] = useState([])
  const [allPages, setAllPages] = useState([])
  const {language} = useSelector(store => store.language)
  const [searchParams, setSearchParams] = useSearchParams()



  useEffect(() => {
    if (!searchParams.get('customSearch')) {
      dispatch(getProducts(language))
    }
  }, [language])

  useEffect(() => {
    setIsOpen(true)
  }, [activeCategory, activeIndustry])

  const getArticle = (name) => {
    if (name === 'Провода для бани/сауны (жаростойкие)' || name === 'Провода для бани/сауны') {
      return (        
        <p className={s.paragraph}>
            К выбору кабеля для прокладки в бане или в сауне стоит подходить в высшей степени ответственно, поскольку от того, насколько правильно будет сделан этот выбор, зависит не только надежность электропроводки, но и безопасность людей. Особенность банных помещений заключается в том, что температура в них может доходить до +140°C, а влажность может достигать 90%. По этой причине и кабель должен быть подходящим, удовлетворяющим этим непростым условиям.<br/><br/>
            <span className={s.italic}>Обычно в сауне или бане выделяют несколько специфических зон, от которых зависит выбор марки провода:</span><br/><br/>
            •	Парилка: здесь необходимо позаботиться об освещении, подключении электрокаменки или электропечи (если она предусмотрена). <br/><br/>
            •	Помывочная, минимально это: освещение, подключение водонагревателя (при необходимости). <br/><br/>
            •	Комната отдыха или предбанник: освещение, подключение электроприборов (чайник, фен, холодильник, телевизор и т.д.)<br/><br/>
            Обычные электропровода с пластмассовой изоляцией не подходят, так как максимальная температура эксплуатации ограничивается до +70 С. При их использовании в парной риск деформации изоляции и оболочек возрастает, соответственно возрастает угроза короткого замыкания и пожара в худшем случае, или выхода кабеля из строя и необходимости его замены в лучшем.<br/><br/>
            Для подключения освещения и электрической печи необходимо использовать только термостойкие кабели и провода, выполненные из специальных кремнийорганических изоляционных материалов. Наиболее популярные кабельные марки для сауны МЛТПЭ, МЛПЭ, МСТП, БПДОЭ сечением 1,5 мм2. Данный кабель подойдет для подключения освещения непосредственно в парильной зоне. На рынке существуют только одножильные провода этих марок. <br/>
            Помывочная в бане или сауне имеет повышенную влажность, поэтому кабель нужно выбирать с влагостойкой изоляцией, подойдет обычный ВВГнг сечением 1,5 мм2.<br/><br/>
            В предбанных зонах нет такой влажности, как в помывочной, или высоких температур, как в парной, поэтому здесь допустимо использовать марки, которые будут удовлетворять требованиям пожарной безопасности. Например, подойдут ВВГнг-LS или ВВГнг-FRLS сечением 1,5 мм2 для освещения и 2,5 мм2 для розеток.<br/><br/>
            По советам специалистов если в сауне или бане предусмотрен проточный электрический водонагреветель, то его монтируют непосредственно в моечном отделении, накопительный водонагреватель лучше устанавливать в предбанник. Обычно водонагревателей мощностью 1.5 – 2.5 кВт достаточно для бани. Их можно подключать к сети 220 В. Подключение происходит отдельным кабелем типа ВВГ, ВВГнг 3х2,5 мм2 от распределительного щита с автоматом защиты и УЗО. В мойке подключать бойлер в розетку недопустимо, поэтому кабель питания подключается непосредственно к контактам на бойлере.<br/><br/>
            Качественный термостойкий кабель в бане всегда был и остается гарантией надежности и безопасности системы электроснабжения и нагрева.
          </p>
      )
    } else if (name === 'Кабели и провода для сигнализации и видеонаблюдения') {
      return (
        <p className={s.paragraph}>
          Принцип работы пожарных и охранных сигнализационных систем заключается в отслеживании попыток несанкционированного проникновения посторонних лиц или появления точек возгорания с последующей передачей сигнала на блоки управления и оповещения. Соединение основных узлов (пульт управления, контрольная панель, датчики, блок питания и т.д.) обеспечивают провода и  кабели для охранных и пожарных сигнализаций. <br/><br/>
            <span className={s.italic}>Изделия для пожарных систем должны отвечать следующим критериям:</span><br/><br/>
            •	Огнестойкость, возможность работать при возгорании<br/><br/>
            •	Низкое выделение дыма<br/><br/>
            •	Отсутствие галогенных частиц в составе<br/><br/>
            •	Диаметр жилы из меди от 0,5 мм (при работе в помещении)<br/><br/>
            <span className={s.italic}>Требования к проводам для охранной сигнализации:</span><br/><br/>
            •	Высокая прочность к механическим воздействиям, влиянию влаги, химических веществ (особенно актуально при прокладке проводов на открытых площадках)<br/><br/>
            •	Устойчивость к возгоранию (при установке в опасной зоне)<br/><br/>
            •	Отсутствие токсичных веществ внутри изоляционного слоя<br/><br/>
            •	Диаметр сечения от 0,22 мм<br/><br/>
            <span className={s.italic}>Самые распространенные кабели для охранной и пожарной систем:</span><br/><br/>
            •	ШВВП, 2×0,5 мм2 и 2×0,75 мм2, силовой кабель для подключения электроприборов к сети 220В <br/><br/>
            •	NYM, 3×1,5 мм2 и более, силовой кабель для подключения электроприборов к сети с напряжением питания до 660В<br/><br/>
            •	КСПВ и КСПЭВ, 4×0,4 мм2, 8×0,4 мм2, 4×0,5 мм2, 8×0,5 мм2,  кабель с медными однопроволочными жилами, предназначен для прокладки шлейфов охранной сигнализации. С экранированием и без него.<br/><br/>
            •	КВВГнг-LS, 2×0,5 мм2 и 2×0,75 мм2, огнестойкий кабель предназначенный для прокладки систем оповещения и управления пожарной автоматикой<br/><br/>
            •	КПСВВ и другие, 2×0,5 мм2 и 2×0,75 мм2, огнестойкий кабель предназначенный для прокладки систем противопожарной сигнализации<br/><br/>
            Установка любой системы видеонаблюдения, за исключением беспроводной, никогда не обходится без кабеля. По кабелю передается видеосигнал от камеры к видеорегистратору, осуществляется питание, а также подключаются дополнительные устройства, например, микрофон. <br/><br/>
            <span className={s.italic}>На сегодняшний день все кабеля для видеонаблюдения делятся на три группы:</span><br/><br/>
            <span className={s.italic}>1. Коаксиальный кабель</span><br/><br/>
            Используется для подключения аналоговых камер и представляет из себя медную жилу в толстом слое пенистого диэлектрика, покрытую экранирующей защитной оболочкой.<br/><br/>
            Чаще всего используют отечественные марки РК-75-2-13, РК-75-4-12 или их импортные заменители – RG-59, RG-6, RG-11. При выборе коаксиального кабеля необходимо учитывать длину и место прокладки (в помещении или на улице). Когда камера и регистратор находятся на расстоянии 200-300 метров друг от друга, сигнал может ослабнуть. Например, РК-75-2-13 актуален при прокладке линии не длиннее 100 метров, на расстоянии от 100 до 300 метров используется кабель. <br/><br/>
            <span className={s.italic}>Основные преимущества коаксиального кабеля: </span><br/><br/>
            •	устойчивость к помехам;<br/><br/>
            •	низкая цена;<br/><br/>
            •	и передача не только видео, но и аудио сигнала.<br/><br/>
            <span className={s.italic}>Недостатки:</span> <br/><br/>
            •	довольно дорогие коннекторы;<br/><br/>
            •	легкая повреждаемость; <br/><br/>
            •	ограниченное расстояние прокладывания.<br/><br/>
            РК-75-4-12, а при расстояниях свыше 300 метров применяют UTP Кабель RG-6.<br/><br/>
            <span className={s.italic}>2. Комбинированный кабель с питанием</span><br/><br/>
            Комбинированный кабель используется как для аналоговых, так и для цифровых устройств. Используется в тех случаях, когда питание камеры и передача сигнала происходят из одной точки. <br/><br/>
            Представляет из себя все тот же коаксиальный провод с сопротивлением 75 Ом, но с проводом питания. Применение такого кабеля избавит вас от прокладки дополнительных проводов.  <br/><br/>
            В некоторых видах комбинированных кабелей есть провода для подключения дополнительных устройств (того же микрофона), и для управления камерой (например, поворачивающим устройством). Например, кабель ККСЭВ имеет не только коаксиальную жилу, но и разделенные провода для питания и аудио сигнала.  <br/><br/>
            К недостаткам относится цена – это вид кабеля один из самых дорогих. Он необходим для построения системы видеонаблюдения, где каждая камера подключается к сети при помощи отдельного блока питания, каждый выход +12В которого оснащен индивидуальным предохранителем, либо при небольшом количестве камер.  <br/><br/>
            Самые распространённые марки комбинированных кабелей: КВК-2П и КВК-В-2. <br/><br/>
            <span className={s.italic}>3. Витая пара UTP кабель или «витая пара»</span><br/><br/>
            Витая пара применяется, когда расстояние от камеры до устройства приема сигнала составляет от 300 до 1000 м (при помощи витой пары это расстояние можно увеличить до 3 км.). <br/><br/>
            Огромный плюс витой пары – множество проводников под одной оплеткой. Это позволяет подвести питание к камерам, осуществить передачу основных сигналов (видео, аудио) и подключить дополнительные камеры с помощью одной линии.  <br/><br/>
            Есть несколько видов кабеля «витая пара» – UTP, FTP, STP. <br/><br/>
            UTP состоит из 8 изолированных, попарно скрученных проводников, помещенных в общую защитную оболочку. Самый простой вид кабеля. Имеет низкую устойчивость к помехам, если прокладки коммуникаций для видеонаблюдения проходит рядом с электропроводкой, рекомендуем выбрать кабель с защитной оболочкой, например, FTP.  <br/><br/>
            FTP кабель защищен фольгированной экранирующей оболочки, его смело можно прокладывать рядом с кабелями электропроводки и другими источниками помех. Необходимо следить за целостностью фольгированного экрана, и не допускать изгиба более 8 внешних диаметров кабеля.  <br/><br/>
          </p>
      )
    } else if (name === 'Кабель для телевизионных антенн') {
      return (
        <p className={s.paragraph}>
          Установка антенного кабеля в частном доме или квартире требует тщательной подготовки места для его разводки и правильного монтажа соединительных элементов (сплиттеров, коннекторов и розеток).<br/><br/>
          В первую очередь необходимо сразу продумать, где будет проходить вся система. Если антенна находится за пределами дома,  и провод будет проходить по улице, то необходимо выбрать такой, который устойчив к перепаду температур и влаге. Это зависит от плотности оплётки кабеля, его диаметра, специальных пропиток и углеродного слоя. Чем он толще, тем толще жила (а от этого зависит и показатель затухания сигнала) и тем он устойчивее к климатическим и механическим повреждениям. Если антенна находится на окне или балконе, то нет необходимости брать самый большой — вполне хватит кабеля с внешним диаметром 6–7 мм.<br/><br/>
          Провода для спутникового и кабельного телевидения отличаются. То есть, по устройству они полностью одинаковые — жила, изоляция, внешний проводник и оболочка. Только вот спутниковый сигнал вместе с питанием конвекторов проходит по жиле (чаще всего медной), а кабельный — по поверхности проводника. Поэтому провода с тонкой жилой (меньше 1 мм в диаметре) не способны пропускать спутниковый сигнал без помех.<br/><br/>
          <span className={s.italic}>Есть 7 главных факторов выбора кабеля для ТВ:</span><br/><br/>
          •	Материал внешней оболочки. <br/><br/>
          •	Прочность внешнего слоя. <br/><br/>
          •	Длина трассы, по которой будет проходить кабельный канал. <br/><br/>
          •	Мощность поступающего сигнала. <br/><br/>
          •	Диаметр центральной жилы. <br/><br/>
          •	Толщина кабеля. <br/><br/>
          •	Материал центральной жилы. <br/><br/>
          <span className={s.italic}>Самым популярным телевизионным кабелем для цифрового телевидения является:</span>  <strong>РК-75</strong><br/><br/>
          Это российский аналог кабелю RG-6U. Он имеет огромное количество разновидностей по сечению проводника, оплетки и изоляции. Сечение жилы лежит в диапазоне от 0,75 мм2 до 1,63 мм2. Для присоединения телевизора к антенне на расстоянии до 30 м вполне подойдет кабель с сечением 0,75 – 0,9 мм2. Более толстый кабель стоит брать только для спутникового телевидения. Наружная изоляция изготавливается из поливинилхлорида, а оплетка из медной луженой проволоки. Отметим, что все типы РК-75 способны выдерживать температурный режим от -60°С до +60°С, и отлично справляются с влиянием влаги и ультрафиолета, поэтому его можно использовать для наружного монтажа. Величина изгибов должна быть не менее 30 мм — этого вполне достаточно для прохода большинства углов.<br/><br/>
        </p>
      )
    } else if (name === 'Кабель для ввода электричества в дом') {
      return (
        <p className={s.paragraph}>
          Никто не застрахован от случайных ударов током при выходе оборудования из строя, перепадах напряжения или по некоторым менее распространенным причинам. Эффективный и недорогой способ обезопасить от удара током себя и своих близких (работников и подчиненных, если мы говорим о рабочем оборудовании) — заземление. <br/><br/>
          <span className={s.italic}>Марка кабеля заземления выбирается в зависимости от типа заземления: </span><br/><br/>
          •	<span className={s.decoration}>стационарное</span><br/><br/>
          • <span className={s.decoration}>нестационарное</span><br/><br/>
          К стационарному, установленному постоянно заземлению можно отнести следующие примеры - заземление зданий, электропроводки в квартире, неподвижное электрооборудование и т.д. <br/><br/>
          <span className={s.italic}>При стационарном заземлении допустимо использовать медные трехжильные провода:</span><br/><br/>
          •	<span className={s.decoration}>ВВГ</span> -  подходит для внутреннего применения. Для прокладки на улице его нужно помещать в гофре или трубах. Производится с различным количеством жил, есть более подробный обзор этого кабеля на сайте. Для использования в жарких помещениях лучше использовать ВВГнг-ls. Этот кабель жесткий и лучше подходит для стационарного монтажа.<br/><br/>
          •	<span className={s.decoration}>NYM</span> -  зарубежная марка по характеристикам похожа на ВВГ. Жесткий.<br/><br/>
          •	<span className={s.decoration}>ВБбШв</span> – подходит для наружного применения и закапывания в траншею, часто используется для подключения частного дома к сети. Жесткий.<br/><br/>
          •	<span className={s.decoration}>ПВС</span> – неплохо подходит для подключения электроинструмента и удлинителей, потому что состоит из многопроволочных гибких жил. Производится в двух и в трёхжильном варианте.<br/><br/>
          •	<span className={s.decoration}>ШВВП</span> – аналогично предыдущему, только он не круглый, а плоский.<br/><br/>
          •	<span className={s.decoration}>ESUY</span> – одножильный мягкий медный провод.<br/><br/>
          Для подключения провода заземления к сантехнике и прочему в ванне можно использовать одножильные провода с маркировкой ПВ. Цифра после этих букв говорит о классе гибкости, где ПВ-1 жесткая жила, а ПВ-4 или ПВ-6 многопроволочная гибкая жила.<br/><br/>
          При нестационарном (переносном) заземлении лучше использовать гибкие провода для заземления типа ПВ6-3, ПВ6-3п, МГКзВ, ПВ-3. Переносные заземления необходимы для защиты во время работы на отключенных участках электрических сетей для предотвращения случайной подачи напряжения на линию или возникновения наведенного напряжения. Кабели для заземления переносного типа обладают повышенной гибкостью и дольше служат.<br/><br/>
          Конструктивно кабели для заземления ПВ6-3 и ПВ6-3п представляют собой многопроволочные медные жил, изолированные прозрачным ПВХ пластикатом.<br/><br/>
          Провод ПВ-3 помимо заземления используют в качестве установочного провода. ПВ-3 изготавливают различных расцветок, однако для кабеля заземления должна использоваться только желто-зеленая изоляция. Конструктивно ПВ3 представляет собой тоже многопроволочную жилу (более низкий класс гибкости) в ПВХ изоляции. <br/><br/>
        </p>
      )
    } else if (name === 'Провода для светодиодных ламп и низковольтного освещения') {
      return (
        <p className={s.paragraph}>
          Современные кабели для светильников выпускаются в виде проводных изделий, подводящих питающее напряжение к осветительным приборам различного класса. Это могут быть как уличные фонари, так и бытовые устройства типа люстр, светодиодных и точечных ламп или настенные бра. Для каждой разновидности осветительного прибора потребуется подобрать определенный тип кабеля, представленного на отечественном рынке огромным количеством наименований.<br/><br/>
          При выборе кабеля для подключения светильников того или иного типа важно определиться с техническими параметрами, которым он должен удовлетворять. <br/><br/>
          Особо следует выделить уличные осветители, к питающей подводке которых предъявляются следующие требования: <br/><br/>
          •	У них должна быть прочная изоляционная оболочка, соответствующая классу защищенности IP65.<br/><br/>
          •	Повышенная защищенность от влаги.<br/><br/>
          •	Широкий диапазон рабочих температур (от минус 50 до плюс 50 градусов).<br/><br/>
          •	Эти изделия должны быть устойчивы к ультрафиолетовому облучению.<br/><br/>
          Самыми популярными видами являются:<br/><br/>
          •	ВВГ состоит из одной или нескольких медных жил, заключенных в виниловую оболочку. В его обозначении "ВВ" указывает на то, что оболочка жил и внешняя изоляция изготовлены из поливинилхлорида. Последняя буква "Г" означает, что снаружи этот провод ничем не защищенный –голый (на изоляции нет брони, в частности).При наружной прокладке для этого провода потребуются дополнительные защитные средства. Наличие первой буквы "А" (АВВГ) означает, что жилы кабеля изготавливаются из более дешевого материала – алюминия. Индекс "НГ" на конце маркировки означает его негорючесть. <br/><br/>
          •	NYM – это универсальный монтажный провод, применяемый при внутренней прокладке электропитания для светильников. Он производится по европейскому стандарту DIN 57250, который определяет его, как изделие пониженной горючести. В отличие от российских аналогов в этом проводе предусмотрен слой огнеупорной резины, расположенной между шинной изоляцией и внешней оболочкой. Изделия этого класса допускается использовать в качестве питающего кабеля для LED светильников, работающих в закрытых помещениях. Провод под обозначением ВВГ состоит из одной или нескольких медных жил, заключенных в виниловую оболочку. В его обозначении "ВВ" указывает на то, что оболочка жил и внешняя изоляция изготовлены из поливинилхлорида. Последняя буква "Г" означает, что снаружи этот провод ничем не защищенный –голый (на изоляции нет брони, в частности).При наружной прокладке для этого провода потребуются дополнительные защитные средства. <br/><br/>
          •	ПВС - многожильный силовой провод с двойной ПВХ изоляцией. Характеризуется износоустойчивостью. Кабель гибкий, благодаря чему часто используется на финальных участках электропроводки для подсоединения приборов, электроинструментов, из него изготавливают удлинители и с его помощью подключают садовую технику. Рекомендован для сетей с напряжением до 1 кВ. Общие принципы подбора кабеля для частного жилья в новой силовой проводке чаще всего применяется кабель трех проводной (для сети 220 В) или пяти проводной (для сети 380 В). Для розеток подойдет кабель с сечением 2,5 мм², для бытовых систем освещения – с сечением 1,5 мм². Осветительные системы нередко организуются с помощью кабелей марки ШВВП. <br/><br/>

        </p>
      )
    } else if (name === 'Кабель для жилых помещений в домах/квартирах') {
      return (
        <p className={s.paragraph}>
          Качество электрической проводки напрямую влияет на безопасность и комфорт проживания в квартире или доме. Поэтому при монтаже электросети большое внимание необходимо уделять такому важному элементу, как кабель – то есть проводнику, который будет передавать электрическую энергию из электрощита до потребителей. <br/><br/>
          Кабели, использующиеся для прокладки электросети в доме или квартире, как правило, оснащены алюминиевыми или медными жилами. Хоть до сих пор еще можно встретить пользователей, которые используют кабели с алюминиевыми жилами, но в настоящее время медный вариант считается более безопасным и эффективным, так как медь обладает высокой проводимостью, меньшей ломкостью при повторных изгибах и высокой устойчивостью к коррозии. <br/><br/>
          Для проводки в квартире, как правило, выбирают между ВВГ и NYM. Жила данных кабелей монолитная и жесткая, т.к. все розетки и выключатели рассчитаны на монтаж жестким проводом.<br/><br/>
          NYM - медный кабель, по исполнению аналогичен кабелю ВВГнг, номинальное напряжение 660 В. Выпускается однопроволочный многожильный NYM-кабель с сечением 1.5-10 мм2 и многопроволочный с сечением от 16 мм2. Число жил 1-5, изоляция и оболочка из ПВХ, негорючесть обеспечивает наполнитель из резины между изоляцией жил и кабельной оболочкой.<br/><br/>
          ВВГ - имеет одножильные медные проводники, изоляцию и оболочку из ПВХ пластиката, используется в помещениях с нормальной и повышенной влажностью. Рассчитан на напряжение до 660 Вольт. Относится к гибким небронированным силовым проводам. Может включать от 1 до 5 жил, сечением от полутора до 240 «квадратов». Форма проводника круглая, плоская или треугольная.<br/><br/>

          <span className={`${s.center} ${s.italic}`}>Для подключения не подходит:</span>
          <span className={`${s.center} ${s.italic}`}>ПВС, ШВВП, ПВВП.</span>
        </p>
      )
    } else if (name === 'Кабель для гаражных помещений') {
      return (
        <p className={s.paragraph}>
          Гараж зачастую – это не просто место, где в безопасности хранится автомобиль. Многие используют гараж не только по прямому назначению, а организуют там мини мастерскую по ремонту домашней утвари или в качестве склада.<br/><br/>
          Как бы то ни было, но иметь электричество в таком полезном и многофункциональном месте необходимо. Всегда найдется ситуация, когда просто необходимо включить свет или использовать розетку. Поэтому стоит задуматься о подключении своего гаража к электрической сети.<br/><br/>
          <span className={s.italic}>Способов подключения к линии ЛЭП может быть два:</span><br/><br/>
          •	<span className={s.decoration}>воздушный</span> – от линии электропередач смонтированной на столбах;<br/><br/>
          •	<span className={s.decoration}>подземный</span> – от трансформаторной установки, расположенной в пределах гаражного кооператива или силового щита индивидуального дома.<br/><br/>
          Каждый их этих способов требует выбора кабеля особой конструкции. Для подвода электроэнергии воздушным способом прокладки – учитывают длину кабеля, его вес ветровую нагрузку, возможность намерзания снега и льда в зимнее время, которые повышают вероятность обрыва. <br/><br/>
          При устройстве подземной подводки предъявляются иные требования. <br/><br/>
          <span className={s.italic}>Выбор кабеля должен учитывать несколько повреждающих факторов:</span><br/><br/>
          •	агрессивную внешнюю среду, связанную с химическим составом почвы;<br/><br/>
          •	наличием блуждающих токов;<br/><br/>
          •	вероятностью механических повреждений при производстве строительных или ремонтных работ, укладке дорожного полотна;<br/><br/>
          •	растрескивание и усадку грунта.<br/><br/>
          Для подземной проводки лучше использовать  бронированные кабели – один из таких АВБШВнг(А). Можно, конечно, обойтись ВВГ или АВВГ, но для них нужно предварительно протянуть трубы.<br/><br/>
          Электромонтаж внутри следует производить по стенам в кабельных каналах и защитных трубах. Если в помещении не будет использоваться оборудование потребляющее много энергии, то для освещения можно использовать ВВГ или ВВГ-П сечением 1,5 кв.мм., для проводки от автоматического выключателя к розеткам можно применять трехжильный ВВГ-П или АВВГ-П с сечением 2,5 кв.мм. Для пожарной сигнализации используется провод специального назначения ПВВС, для охранной сигнализации – МК-1 или МК-4. Для подключения подвижного оборудования лучше использовать гибкий кабель КГ, он способен выдерживать частые вибрации и изгибы.<br/><br/>
        </p>
      )
    } else if (name === 'Кабель для автомобильной проводки и магнитол') {
      return (
        <p className={s.paragraph}>
          Современный автомобиль не будет работать без электричества: оно необходимо для старта двигателя, освещения дороги и салона, работы электронных систем. Навигация, кондиционирование воздуха, работа аудиосистем, сигнализации и многое другое – все это немыслимо без электричества. Соответственно количество проводов в автомобиле исчисляется даже не десятками, а сотнями метров. <br/><br/>
          Специфические условия эксплуатации проводки предъявляют к ней особые требования. Самым важным требованием, предъявляемым к автомобильной проводке, является надежность. Провода в таком случае подвергаются большому числу внешних воздействий: трению, тряске, ударам, попаданию на них масла и топлива, сильным изменениям температур. <br/><br/>
          Поэтому провод для автопроводки должен иметь медные жилы и обладать достаточной гибкостью. Его изоляция - быть устойчивой как к низким, так и к высоким температурам: не затвердевать, не покрываться трещинами, не размягчаться и не распространять горение при возгорании.<br/><br/>
          <span className={s.italic}>В автомобильном электрооборудовании применяются провода двух типов: высоковольтные и низковольтные.</span><br/><br/>
          •	<span className={s.decoration}>Проводник высокого напряжения</span> применяется в системах зажигания. <br/><br/>
          •	<span className={s.decoration}>Низковольтный провод используется</span> для монтажа бортовой сети. <br/><br/>
          <span className={s.italic}>Самыми распространенными марками проводов для автомобильной проводки являются:</span><br/><br/>
          •	<span className={s.decoration}>ПВА</span> – низковольтный проводник, специально разработан для функционирования в проводке автомобиля, обладает высокой гибкостью не ниже 5 класса. Устойчив к нагреву до +105 С, а заявленный производителем период работы на предельных температурах достигает 96 часов при +135 С. Удовлетворяет всем требованиям надежности. Спецификация ПВАМ, где М – малогабаритный, различается более тонкой изоляцией и массой. Используется в легковых и грузовых автомобилях при монтаже бортовой сети.<br/><br/>
          •	<span className={s.decoration}>ПГВА</span> – еще один проводник низкого напряжения для автомобилей. Не такой гибкий как <span className={s.decoration}>ПВА</span> (3 класс гибкости) и менее устойчивый к высоким температурам (до +70 С). Преимущественно используется в пассажирском транспорте, грузовой и сельскохозяйственной технике. При работе в условиях электромагнитных помех используется его экранированная версия <span className={s.decoration}>ПГВАЭ</span>.<br/><br/>
          •	<span className={s.decoration}>НВМ</span> – универсальные проводники используемые при фиксированном монтаже. Луженая медь как материал проводника гарантирует качественную пайку, а допустимый показатель рабочей температуры достигает +105 С. Спецификации <span className={s.decoration}>НВ</span> и <span className={s.decoration}>НВМ</span> различаются лишь гибкостью.<br/><br/>
          В автоэлектрике так же возможно использовать провода <span className={s.decoration}>ПВ3, ПВ4, ПуГВ</span>. Однако нужно помнить, что они должны быть смонтированы стационарно, а их устойчивость к температурам составляет +70 С. Также они нуждаются в дополнительной защите от внешних воздействий. Чаще всего их применяют для подведения питания к автомобильной акустике.<br/><br/>
          Для определения сечения проводника нам понадобятся: <br/><br/>
          •	максимальное потребление тока всего оборудования в автомобиле; <br/><br/>
          •	дистанция от монтируемого электрооборудования до аккумулятора;<br/><br/>
          •	номинальное напряжение источника питания. <br/><br/>
        </p>
      )
    } else if (name === 'Кабель для интернета и телефонной связи') {
      return (
        <p className={s.paragraph}>
          Как для подключения компьютера, так и для создания сети необходим кабель для интернета. Это хоть и пассивная составляющая, но не менее важная, чем остальные элементы.<br/><br/>
          Чтобы неправильно подобранный проводник не вывел из строя оборудование, нужно ознакомиться с видами кабелей, их особенностями и предназначением. <br/><br/>
          Основные виды кабелей:<br/><br/>
          •	витая пара;<br/><br/>
          •	оптоволоконный;<br/><br/>
          •	коаксиальный.<br/><br/>
          <span className={s.italic}>Вид №1 — витая пара.</span><br/><br/>
          Для возможности после прочтения обозначения получить необходимые сведения о витой паре, была придумана международная классификация кабелей.<br/><br/>
          В основу классификации заложили формулу: <br/><br/>
          •	АА/ВСС. Здесь первая пара символов <strong>«АА»</strong> указывает на присутствие общего экрана.<br/><br/>
          •	Символ <strong>«В»</strong> информирует о наличии индивидуальной защиты каждой пары.<br/><br/>
          Два последних символа расскажут о виде скрутки. <br/><br/>
          •	Если это <strong>«ТР»</strong>, то проводники скручены попарно. <br/><br/>
          •	В случае <strong>«TQ»</strong> — присутствует скрутка четверками.<br/><br/>
          Наличие защиты является особенной характеристикой витой пары.<br/><br/>
          Таким видом провода для интернета, как витая пара можно объединить в одну сеть устройства, удаленные друг от друга не более чем на 100 м. Если вы планируете использовать этот вид кабеля, рекомендуем ознакомиться с лучшими способами соединения витой пары между собой.<br/><br/>
          <span className={s.italic}>Вид №2 — оптоволоконный сетевой кабель</span><br/><br/>
          На сегодня более быстрой технологии передачи информации в интернете, чем оптоволокно, не существует.<br/><br/>
          Через оптоволоконные кабели, протянутые по дну океана, высокоскоростной интернет связывает не только города, но и материки.<br/><br/>
          К преимуществам оптического кабеля относятся:<br/><br/>
          •	хорошие пропускные данные;<br/><br/>
          •	долговечность;<br/><br/>
          •	быстрота обнаружения несанкционированного доступа, что повышает безопасность сети;<br/><br/>
          •	подавление шума, достаточная степень защиты от помех;<br/><br/>
          •	значительная скорость передачи информации;<br/><br/>
          •	возможность реализации дополнительных опций.<br/><br/>
          Оптоволоконные кабели бывают одномодовыми и многомодовыми. Отличаются они друг от друга режимами продвижения лучей света в кабеле.<br/><br/>
          Несмотря на небольшую стоимость и все другие преимущества кабеля этого вида, существует два фактора ограничивающие их применение — оборудование и адаптеры для оптоволоконной сети очень дорогие.<br/><br/>
          Оконцовка кабеля выполняется с применением дорогостоящего оборудования.<br/><br/>
          Непростым является монтаж, а также ремонт сети, выполнять работы могут только высококвалифицированные специалисты. По этой причине оптоволоконный кабель применяют в крупных сетях.<br/><br/>
          <span className={s.italic}>Вид №3 — коаксиальный провод</span><br/><br/>
          Основная характеристика — волновое сопротивление. От него зависит качество кабеля и передаваемого сигнала. Влияет на эти величины материал и его диэлектрическая проницаемость, а также индуктивность, емкость, удельное сопротивление. Сила сигнала зависит от расстояния передачи.<br/><br/>
          Существует две разновидности кабелей такого вида: тонкий и толстый. Диаметр первого — не более 0,5 см. Его специфические черты — повышенная гибкость и быстрое затухание сигнала, поэтому сигнал с его помощью передают на незначительные расстояния со скоростью до 10 Мбит/с.<br/><br/>
          Сечение толстого коаксиального кабеля — 1 см. Поскольку жесткость у него повышенная, монтаж осуществляют с использованием недешевых специальных приспособлений.<br/><br/>
        </p>
      )
    } else if (name === 'Кабель для удлинителей, сварочных аппаратов') {
      return (
        <p className={s.paragraph}>
          Несмотря на тщательное планирование расположения и количества розеток в помещении, никто не застрахован от необходимости использования переносок. А на строительных объектах и загородных участках без них практически не обойтись. При этом не всегда заводские устройства способны решить проблему из-за нехватки длины или несоответствия допустимого номинального тока.<br/><br/>
<span className={s.italic}>Наиболее практичными вариантами кабелей для комплектации удлинителя являются:</span><br/><br/>
•	<span className={s.decoration}>Марка КГ</span><br/><br/>
Морозостойкий тип кабеля, который рекомендуется к применению на улице, но без длительного прямого воздействия ультрафиолетовых лучей. Они способствуют разрушению резиновой защитной оболочки токопроводящих жил. Данная разновидность кабельных линий отлично подходит для переносок, часто подвергающихся изгибу и сдавливанию. Такие условия характерны для строительных объектов. Марку КГ допускается эксплуатировать при температуре от — 40 до + 50 градусов Цельсия. Расчетное количество циклов изгиба составляет не менее 30 тысяч с четырехлетним сроком службы.<br/><br/>
•	<span className={s.decoration}>Марка КГН</span><br/><br/>
Может использоваться в условиях воздействия агрессивных факторов. К ним относятся различные химические вещества, масла и искры. <br/><br/>
<span className={s.italic}>Основные технические характеристики:</span><br/><br/>
1.	рабочий диапазон температур — от – 30 до + 50;<br/><br/>
2.	защитный слой из резины;<br/><br/>
3.	предельное количество циклов изгиба — минимум 30 тысяч;<br/><br/>
4.	эксплуатационный ресурс — 2,5 года.<br/><br/>
•	<span className={s.decoration}>Марка ПВС</span><br/><br/>
Изоляционный слой токопроводящих жил из ПВХ-пластика не разрушается под воздействием солнечных лучей, но теряет свою пластичность при отрицательных температурах. В связи с этим рекомендуется применять кабель ПВС для переносок, используемых при положительных температурах. Согласно заводским показателям, срок службы марки ПВС составляет более шести лет при температуре в пределах от – 25 до + 40 градусов Цельсия. Провод рассчитан на выдерживание не менее тридцати тысяч циклов изгиба.<br/><br/>
•	<span className={s.decoration}>Марка ПРС</span><br/><br/>
Данный провод для удлинителя может использоваться как на открытом воздухе, так и внутри помещений. <br/><br/>
<span className={s.italic}>Обладает следующими показателями:</span><br/><br/>
1.	эксплуатационный температурный диапазон — от – 40 до + 40;<br/><br/>
2.	количество циклов на изгиб — 30 тысяч;<br/><br/>
3.	изоляция — резина;<br/><br/>
4.	срок службы — более шести лет.<br/><br/>
<span className={s.italic}>Наиболее используемыми сечениями проводов для переносок длиной от 5 до 10 метров являются:</span><br/><br/>
1.	0,75-1 мм2 — для напряжения 220 Вольт;<br/><br/>
2.	1,5-2,5 мм2 — при напряжении 380 Вольт.<br/><br/>
Поиск сварочного кабеля после покупки инверторного аппарата нельзя назвать редкостью. Каким бы хорошим не было сварочное оборудование, провода, идущие с ним в комплекте, часто не соответствуют ожиданиям. Некоторых не устраивает материал жилы, других – длина, третьих – гибкость – и это далеко не все, что может вызывать недовольство.<br/><br/>
<span className={s.italic}>Наиболее распространенными вариантами сварочного кабеля являются:</span><br/><br/>
•	<span className={s.decoration}>КГ</span><br/><br/>
Данный вид можно встретить практически в любом магазине. Аббревиатура расшифровывается как «кабель гибкий». Шнур такого плана можно подключать к сети на 220 или 380 В, и, конечно же, использовать для массы и держателя. Он работает с переменным и постоянным током (до 660 В и 1000 В) при частоте до 400 Гц. <br/><br/>
•	<span className={s.decoration}>КОГ1</span><br/><br/>
Является менее универсальным и прочным, чем предыдущий вариант. Но обладает особой гибкостью – в его жиле используются более тонкие проводки. Это означает меньший радиус разворота, способствующий более комфортному процессу сварки в труднодоступных местах. Данный сварочный кабель пригодится при высотных работах, так как его удобно наматывать на руку для надежного закрепления. Пропускная способность – 220 В при 50 Гц. <br/><br/>
•	<span className={s.decoration}>КГН</span><br/><br/>
Буква «Н» в маркировке подобного кабеля для сварки означает его негорючесть (устойчивость к высоким температурам). Изоляция КГН изготовлена из жаростойкого материала, выдерживающего нагревание 200 °C и более. Главная особенность провода дает возможность осуществлять сварку или резку в экстремальных температурных условиях (например, сваривание металлов вблизи или в эпицентре источника возгорания). Из-за устойчивости к жару КГН-кабели востребованы среди работников МЧС, ремонтных бригад в судостроительной отрасли и т.д. <br/><br/>
•	<span className={s.decoration}>КГ</span>-ХЛ<br/><br/>
Как уже говорилось ранее, «КГ» – кабель гибкий, остальные две буквы в конце говорят о том, что сварочный кабель может эксплуатироваться на холоде и морозе, достигающем - 60 °C. Способность сохранять гибкость при низких температурах обеспечена использованием в составе оплетки специального каучука.<br/><br/>
•	<span className={s.decoration}>КПЭС</span><br/><br/>
Сварочные кабели такого плана применяются для полуавтоматической сварки. Они отличаются наличием внутри спиралевидной трубки для пропуска проволоки. Представители марки рассчитаны на работу с любым видом тока под напряжением 42/48 В при частоте 50 Гц. Из весомых знаний о КПЭС стоит выделить то, что прослужат они недолго (минимум полтора месяца - максимум полтора года), а на стоимость существенно повлияет диаметр.<br/><br/>
•	<span className={s.decoration}>КВС</span><br/><br/>
Это сварочные кабели с дополнительным защитным слоем полихлорвинила на жиле. Отличаются большей устойчивостью к истиранию, что делает их отличным вариантом для использования на строительных объектах. <br/><br/>
Стоит отметить, что представители марки КВС куда менее гибки, чем остальные. Помимо устойчивости к механическому воздействию, они легко переносят низкие температуры и могут свободно использоваться в диапазоне от -40 до 40 градусов Цельсия.<br/><br/>
•	<span className={s.decoration}>КГТ</span><br/><br/>
Буква «Т» в конце означает возможность применения в условиях тропиков. Подобные сварочные кабели характеризуются способностью выдерживать температуру окружающей среды до +85 °C. Выпускаются с покрытием, которое успешно противостоит воздействию влаги, грибка и плесени. <br/><br/>
        </p>
      )
    } else {
      return
    }
  }

  return (

    <div className={s.container}>

      {isOpen && undefined!==activeIndustry && activeIndustry.length && getArticle(activeIndustry[1]) ? (
        <>
        <div className={s.topic}>
          <p className={s.preTitle}>Статья</p>
          <h1 className={s.title}>{activeIndustry[1]}</h1>

          {getArticle(activeIndustry[1]) ? getArticle(activeIndustry[1]) : ''}
        </div>
        </>
      ) : (
        ''
      )}

      {/* {getArticle(activeIndustry[1]) ? isOpen && undefined!==activeIndustry && activeIndustry.length  ? <div className={s.button} onClick={() => {
        scrollIntoView(document.body.querySelector('#root'), {
          time: 0,
          align: {
            top: 0.1,
          },
        })

        setIsOpen(false)
      }}>
        <button>Скрыть статью</button>
      </div> : <div className={`${s.button} ${s.active}`}  onClick={() => {
        scrollIntoView(document.body.querySelector('#root'), {
          time: 0,
          align: {
            top: 0.1,
          },
        })
        
        setIsOpen(true)
      }}>
        <button>Открыть статью</button>
      </div> : ''} */}

      <p className={s.title}>Товары и категории</p>

      <div className={s.cards}>
        {!data &&
          <h1>Ничего не найдено</h1>
        }
        {data && !showProducts.length
          ? activePage === 1
            ? data.slice(0, 20).map((card, idx) => (
                <div className={s.card} key={idx}>
                  <div className={s.logoImage}>
                      <BaseImage src={getImageSrc(card?.image)} alt={card.name} className={s.image} fallbackSrc={provod} />
                  </div>
                  {card.oldPrice &&
                    <p className={s.oldPrice}>
                    <s>{card.oldPrice}</s>
                    <span>{card.discount}</span>
                  </p>
                  }
                  {card.price &&
                    <p className={s.price}>{card.price}р</p>
                  }
                  {!card.price && 
                 
                    <p className={s.price}>По запросу</p>
                  }
                  <p className={s.name}>{card.name}</p>

                  <div className={s.flexCard}>
              
                    <Link to={'/shop/product/' + card.id}>Подробнее</Link>
                    <span className={s.img} onClick={() => 
                      dispatch(addToCart({id: card.id, title: card.name, img: card.image
                          , price: card.price, quantity: 1}))
                    }>
                      <svg width="26" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g
                          clipPath="url(#prefix__clip0_131_103)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9.764 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.08-.993-.595 0-1.078.445-1.078.993s.483.992 1.079.992zM21.63 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.079-.993s-1.079.445-1.079.993.483.992 1.079.992zM1.133.992h4.315L8.34 14.281c.1.457.37.867.764 1.16a2.28 2.28 0 001.394.438h10.486c.506.008 1-.147 1.394-.439.394-.292.665-.702.763-1.16l1.726-8.326H6.527" />
                        </g>
                        <defs>
                          <clipPath id="prefix__clip0_131_103">
                            <path
                              fill="#fff"
                              transform="translate(.055)"
                              d="M0 0h25.89v23.818H0z"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              ))
            : data.slice((activePage - 1) * 20, (activePage - 1) * 20 + 20).map((card, idx) => (
                <div className={s.card} key={idx}>
                  <div className={s.logoImage}>
                      <BaseImage src={getImageSrc(card?.image)} alt={card.name} className={s.image} fallbackSrc={provod} />
                  </div>
                  {card.oldPrice &&
                    <p className={s.oldPrice}>
                    <s>{card.oldPrice}</s>
                    <span>{card.discount}</span>
                  </p>
                  }
                  {card.price &&
                    <p className={s.price}>{card.price}р</p>
                  }
                  {!card.price && 
                 
                    <p className={s.price}>По запросу</p>
                  }
                  <p className={s.name}>{card.name}</p>

                  <div className={s.flexCard}>
                    <Link to={'/shop/product/' + card.id}>Подробнее</Link>
                    <span className={s.img} onClick={() => 
                      dispatch((addToCart({id: card.id, title: card.name, img: card.img, price: card.price})))
                    }>
                      <svg width="26" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g
                          clipPath="url(#prefix__clip0_131_103)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9.764 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.08-.993-.595 0-1.078.445-1.078.993s.483.992 1.079.992zM21.63 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.079-.993s-1.079.445-1.079.993.483.992 1.079.992zM1.133.992h4.315L8.34 14.281c.1.457.37.867.764 1.16a2.28 2.28 0 001.394.438h10.486c.506.008 1-.147 1.394-.439.394-.292.665-.702.763-1.16l1.726-8.326H6.527" />
                        </g>
                        <defs>
                          <clipPath id="prefix__clip0_131_103">
                            <path
                              fill="#fff"
                              transform="translate(.055)"
                              d="M0 0h25.89v23.818H0z"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              ))
          : showProducts
              .slice((activePage - 1) * 20, (activePage - 1) * 20 + 20)
              .map((card, idx) => (
                <div className={s.card} key={idx}>
                  <div className={s.logoImage}>
                      <BaseImage src={getImageSrc(card?.image)} alt={card.name} className={s.image} fallbackSrc={provod} />
                  </div>
                  {card.oldPrice &&
                    <p className={s.oldPrice}>
                    <s>{card.oldPrice}</s>
                    <span>{card.discount}</span>
                  </p>
                  }
                  {card.price &&
                    <p className={s.price}>{card.price}р</p>
                  }
                  {!card.price && 
                 
                    <p className={s.price}>По запросу</p>
                  }
                  <p className={s.name}>{card.name}</p>

                  <div className={s.flexCard}>
                    <Link to={'/shop/product/' + card.id}>Подробнее</Link>
                    <span className={s.img} onClick={() => 
                      dispatch(addToCart({id: card.id, title: card.name, img: card.img, price: card.price}))
                    }>
                      <svg width="26" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g
                          clipPath="url(#prefix__clip0_131_103)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9.764 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.08-.993-.595 0-1.078.445-1.078.993s.483.992 1.079.992zM21.63 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.079-.993s-1.079.445-1.079.993.483.992 1.079.992zM1.133.992h4.315L8.34 14.281c.1.457.37.867.764 1.16a2.28 2.28 0 001.394.438h10.486c.506.008 1-.147 1.394-.439.394-.292.665-.702.763-1.16l1.726-8.326H6.527" />
                        </g>
                        <defs>
                          <clipPath id="prefix__clip0_131_103">
                            <path
                              fill="#fff"
                              transform="translate(.055)"
                              d="M0 0h25.89v23.818H0z"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              ))}
      </div>

      <PaginationCatalogue
        activePage={activePage}
        setActivePage={setActivePage}
        allPages={allPages}
        setAllPages={setAllPages}
        activePages={activePages}
        setActivePages={setActivePages}
        data={showProducts.length > 0 ? showProducts : data}
      />
    </div>
  )
}

export default MainTopic
