import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import s from './Header.module.scss'
import mapPin from '../../../media/main/map-pin.svg'
import clock from '../../../media/main/clock.svg'
import phone from '../../../media/main/phone.svg'
import logo from '../../../media/main/logo.svg'
import search from '../../../media/main/search.svg'
import user from '../../../media/main/user.svg'
import shoppingCart from '../../../media/main/shopping-cart.svg'
import MobileHeader from './MobileHeader'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import i18next from 'i18next'
import {useTranslation} from 'react-i18next'
import {setLanguage} from '../../../store/reducers/LanguageReducer'
import { publicSearch } from 'store/async/asyncSearch'

const Header = ({breadcrumb}) => {
  const {authenticated} = useSelector(store => store.auth)
  const [isActive, setIsActive] = useState()
  const [activeLanguage, setActiveLanguage] = useState(0)
  const cart = useSelector((state) => state.persistedReducer.cart)

  const getTotalQuantity = () => {
    let total = 0
    if(undefined!==cart){
      cart.forEach(item => {
        total += item.quantity
      })
    }

    return total
  }
  const {t, i18n} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [inputSearch, setInputSearch] = useState('')

  useEffect(() => {
    if (isActive) {
      document.body.classList.add('hidden')
    } else {
      document.body.classList.remove('hidden')
    }
  }, [isActive])

  useEffect(() => {
    if (!activeLanguage) {
      i18next.changeLanguage('ru')
      dispatch(setLanguage('ru'))
    } else if (activeLanguage === 1) {
      i18next.changeLanguage('en')
      dispatch(setLanguage('en'))
    } else {
      i18next.changeLanguage('be')
      dispatch(setLanguage('be'))
    }
  }, [activeLanguage])

  const goSearchValue = (info) => {
    if (!info) return
    dispatch(publicSearch(info))
    //navigate('/shop/catalogue?customSearch=' + info)
  }

  return (
    <header className={s.containerWrapper}>
      <div className={s.headerinfo}>Данная страница находится в процессе разработки. Приносим извинения за доставленные неудобства! Тел. фирменного магазина <a href="+375291114093" target="_blank">+375(29)111-40-93</a>.</div>
      <div className={s.header}>
        <div className={s.flex}>
          <div className={s.firstItem}>
            <div className={s.item}>
              <img className={s.img} src={mapPin} alt="map pin" />
              <div className={s.wrapper}>
                <p className={s.preTitle}>Наш адрес:</p>
                <p className={s.title}>г. Мозырь, ул. Октябрьская, 14.</p>
              </div>
            </div>

            <div className={s.item}>
              <img className={s.img} src={clock} alt="map pin" />
              <div className={s.wrapper}>
                <p className={s.preTitle}>График работы:</p>
                <p className={s.title}>Вт-Пт c 9:00 до 18:00 (обед 14:30-15:30)<br/>Сб c 9:00 до 15:00 (обед 12:00-12:30)<br/>Вс, Пн - выходной</p>
              </div>
            </div>

            <div className={s.item}>
              <img className={s.img} src={phone} alt="map pin" />
              <div className={s.wrapper}>
                <p className={s.title}>
                  <a href="+375291114093" target="_blank">
                    +375 (29) 111-40-93
                  </a>
                </p>
                <p className={s.title}>
                  <a href="+375236372575" target="_blank">
                    +375 (236) 37-25-75
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className={s.secondItem}>
            {/* <button className={s.button}>Оставить заявку</button> */}
            <div
              className={`${!activeLanguage ? s.left : activeLanguage === 1 ? s.center : s.right} ${
                s.changeLanguage
              }`}
            >
              <span
                onClick={() => {
                  setActiveLanguage(0)
                }}
              >
                <div>РУС</div>
              </span>
              <span
                onClick={() => {
                  setActiveLanguage(1)
                }}
              >
                <div>ENG</div>
              </span>
              <span
                onClick={() => {
                  setActiveLanguage(2)
                }}
              >
                <div>БЕЛ</div>
              </span>
            </div>
          </div>
        </div>
        <div className={s.secondRow}>
          <div className={s.logo}>
            <Link to="/shop">
              <img src={logo} alt="Logo" />
            </Link>
            <span>Интернет-магазин</span>
          </div>
          <div className={s.searchWrapper}>
            <img src={search} alt="search panel" onClick={() => goSearchValue(inputSearch)}/>
            <input type="text" placeholder="Поиск" className={s.searchInput} value={inputSearch} onKeyPress={e => e.key === 'Enter' ? goSearchValue(e.target.value) : ''}
             onChange={(e) => {
              setInputSearch(e.target.value)
            }}/>
          </div>
          <div className={`${s.secondWrapper} ${s.desktopWrapper}`}>
            {/* <div className={s.secondItem}>
              <img className={s.img} src={user} alt="Личный кабинет" />
              {authenticated ? (
                <span>Личный кабинет</span>
              ) : (
                <span>
                  <Link to="/shop/public/auth">Войти</Link>
                </span>
              )}
            </div> */}
            <div className={s.secondItem} onClick={() => navigate('/shop/backet')}>
              <div className={s.itemImageWrapper}>
                <img src={shoppingCart} alt="Корзина" />
                <span className={`${s.counter} ${getTotalQuantity() && s.active}`}>{getTotalQuantity() || 0}</span>
              </div>
              <span>Корзина</span>
            </div>
          </div>

          <button className={`${s.hamburgerLines}`} onClick={() => setIsActive(!isActive)}>
            <span className={`${s.line} ${s.line1}`}></span>
            <span className={`${s.line} ${s.line2}`}></span>
            <span className={`${s.line} ${s.line3}`}></span>
          </button>
        </div>
        <div className={`${s.searchWrapper} ${s.mobile}`} onClick={e => console.log(e)}>
          <img src={search} alt="search panel"/>
          <input type="text" placeholder="Поиск" className={s.searchInput} value={inputSearch} onChange={(e) => {
            setInputSearch(e.target.value)
          }}/>
        </div>
        <div className={s.thirdWrapper}>
          <nav>
            <ul className={s.menu}>
              <li>
                <Link to="/shop/catalogue">Каталог</Link>
              </li>
              <li>
                <Link to="/shop/actions">Товары по акции</Link>
              </li>
              <li>
                <Link to="/shop/bestsellers">Лидеры продаж</Link>
              </li>
              <li>
                <Link to="/shop/payment">Оплата и доставка</Link>
              </li>
              <li>
                <Link to="/shop/rassrochka">Рассрочка</Link>
              </li>
              <li>
                <Link to="/shop/about">О нас</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className={s.mobileBar}>
          <div className={s.secondWrapper}>
            {/* <div className={s.secondItem}>
              <img className={s.img} src={user} alt="Личный кабинет" />
              <span>Личный кабинет</span>
            </div> */}
            <div className={s.secondItem} onClick={() => navigate('/shop/backet')}>
              <div className={s.itemImageWrapper}>
                <img src={shoppingCart} alt="Корзина" />
                <span className={`${s.counter} ${getTotalQuantity() && s.active}`}>{getTotalQuantity() || 0}</span>
              </div>
              <span>Корзина</span>
            </div>
          </div>
          <div className={`${s.secondItem}`}>
            {/* <button className={`${s.button} ${s.active}`}>Оставить заявку</button> */}
            <div className={`${!activeLanguage ? s.left : s.right} ${s.changeLanguage}`}>
              <span onClick={() => setActiveLanguage(0)}>РУС</span>
              <span onClick={() => setActiveLanguage(1)}>ENG</span>
              <span onClick={() => setActiveLanguage(2)}>БЕЛ</span>
            </div>
          </div>
        </div>

        <MobileHeader
          isActive={isActive}
          setIsActive={setIsActive}
          changeLanguage={setActiveLanguage}
          activeLanguage={activeLanguage}
        />

        {breadcrumb && (
          <div className={s.breadcrumb}>
            {breadcrumb.map(br =>
              br.show ? (
                <p className={s.bredcrumbItem} key={br.id}>
                  {br.href ? <Link to={br.href}>{br.title}</Link> : br.title}
                </p>
              ) : (
                ''
              )
            )}
          </div>
        )}
      </div>
    </header>
  )
}
export default Header
