import React, {useEffect, useState} from 'react'
import s from './About.module.scss'
import scrollIntoView from 'scroll-into-view'
import {useOutletContext} from 'react-router-dom'

const About = props => {
  const [activeLanguage, setActiveLanguage] = useState(0)
  const {setBreadCrumb} = useOutletContext()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })

    setBreadCrumb([
      {
        id: 1,
        title: 'Главная',
        href: '/shop',
      },
      {
        id: 2,
        title: 'О нас',
      },
    ])
  }, [])

  return (
    <div>
      <div className={s.body}>
        <div className={s.container}>
          {/* <div className={s.secondItem}>
            <div className={`${!activeLanguage ? s.left : s.right} ${s.changeLanguage}`}>
              <span
                onClick={() => setActiveLanguage(0)}
                className={`${!activeLanguage ? s.active : ''}`}
              >
                Оплата
              </span>
              <span
                onClick={() => setActiveLanguage(1)}
                className={`${activeLanguage ? s.active : ''}`}
              >
                Доставка
              </span>
            </div>
          </div> */}
        <div className="content">
            <h1>О нас</h1>

            <p>Фирменный магазин «Беларускабель» и пункт выдачи интернет магазина находится по адресу <b>г. Мозырь, ул. Октябрьская 14, 1 этаж.</b> </p>
            
            <p>Здесь вы можете приобрести необходимую кабельно-проводниковую продукцию или забрать уже оплаченный товар, который вы заказали через интернет магазин. </p>

            <p>Розничные цены на продукцию указаны в разделе «Каталог». При необходимости, вы можете оставить заявку на интересующий вас вид кабеля, если его нет в наличии. </p>

            <p>Следите за скидками в разделе <a href="/shop/actions">«Товары по акции»</a>, там вы всегда найдете выгодное предложение на продукцию самого высокого качества!</p>

            <p>Также вы можете заказать доставку товара до необходимого пункта. С подробностями по доставке вы можете ознакомиться на странице <a href="/shop/payment">«Оплата и доставка»</a>. </p>
            <br/>
            <p>
                <b>Как оформить заказ:</b><br/>
                Заказ в интернет магазине вы можете оформлять круглосуточно. После оформления заказа с вами свяжется менеджер для уточнения деталей.
            </p>
            <p>
                <b>Время работы магазина и пункта выдачи:</b><br/>
                Вторник-Пятница c 9:00 до 18:00 (обед 14:30-15:30)<br/>
                Суббота c 9:00 до 15:00 (обед 12:00-12:30)<br/>
                Воскресенье, Понедельник - выходной
                <br/>
            </p>
            <p>
                <b>Телефоны:</b><br/>
                +375 (29) 111-40-93<br/>
                +375 (236) 37-25-75<br/>
            </p>











          </div>
        </div>
      </div>
    </div>
  )
}

export default About
