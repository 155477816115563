import React, {useMemo} from 'react'
import s from './CardContent.module.scss'
import provod from '../../../../media/main/default.png'
import DOMPurify from "dompurify";

const CardContent = ({data}) => {
  const productDescription = useMemo(() => {
    if (data?.description) {
      return DOMPurify.sanitize(data.description);
    }
    return null;
  }, [data]);

  return (
    <div>
      <div className={s.flex}>
        <div className={s.flexItem}>
          <img
            src={data.image && data.image.includes('undefined') ? provod : data.image}
            alt="Провод"
            className={`${s.img} ${s.active}`}
          />
        </div>
        {productDescription ? (
            <div className={s.flexItem}>
              <div dangerouslySetInnerHTML={{ __html: productDescription }} />
            </div>
        ) : (
            <div className={s.flexItem}>
              <p className={s.title}>Назначение</p>
              <p className={s.paragraph}>{data.purpose}</p>
            </div>
        )}
      </div>
    </div>
  )
}

export default CardContent
