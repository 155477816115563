import {useState} from "react";

const BaseImage = ({ src, className, fallbackSrc = '', alt = '' }) => {
    const [imgSrc, setImgSrc] = useState(src);

    return (
        <img
            src={imgSrc}
            alt={alt}
            className={className}
            loading="lazy"
            onError={() => setImgSrc(fallbackSrc)}
        />
    )
};

export default BaseImage;
