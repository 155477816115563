import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import {calc} from './languages/calc'
import {production} from './languages/production'
import {management} from './languages/management'
import {qualitypolicy} from './languages/qualitypolicy'
import {testing} from './languages/testing'
import {innovate} from './languages/innovate'
import {photogallery} from './languages/photogallery'
import {work} from './languages/work'
import {checkout} from './languages/checkout'
import {contacts} from './languages/contacts'
import {cardpage} from './languages/cardpage'
import {schedule} from './languages/schedule'
import {shareholders} from './languages/shareholders'
import {modal} from './languages/modal'
import {leaders} from './languages/leaders'
import {dillers} from './languages/dillers'
import {importProd} from './languages/import'
import {products} from "./languages/products.js";

const resources = {
  ru: {
    translation: {
      'Нам доверяют': 'Нам доверяют',

      // HEADER
      'Производим кабельную продукцию c 1959 года': 'Производим кабельную продукцию c 1959 года',
      'г. Мозырь, ул. Октябрьская, 14.': 'г. Мозырь, ул. Октябрьская, 14.',
      'номер приёмной': 'Приёмная',
      'отдел продаж': 'Отдел продаж',
      'Мы в социальных сетях': 'Мы в социальных сетях',
      'Фирменный магазин': 'Фирменный магазин',      
      // 'Оставить заявку': 'Оставить заявку',
      'Личный кабинет': 'Личный кабинет',
      'Войти в аккаунт': 'Войти',
      'Меню каталог': 'Каталог',
      'Меню производство': 'Производство',
      'Менеджмент качества': 'Менеджмент качества',
      'Центральная заводская лаборатория': 'Центральная заводская лаборатория',
      'Инновационная продукция': 'Инновационная продукция',
      'Меню О компании': 'О компании',
      'Меню История': 'История',
      'Меню Руководство': 'Руководство',
      'СМИ о нас': 'СМИ о нас',
      'Меню Фотогалерея': 'Фотогалерея',
      'Меню Дилеры': 'Дилеры',
      'Меню Кибербезопасность': 'Кибербезопасность',
      'Наши достижения': 'Достижения',
      'Политика в отношении обработки персональных данных': 'Политика в отношении обработки персональных данных',
      'Политика видеонаблюдения':'Политика видеонаблюдения',
      'Меню Контакты': 'Контакты',
      'Электронный каталог': 'Электронный каталог',
      'Предложения по импортозамещению': 'Предложения по импортозамещению',
      'Калькулятор кабеля, база для AUTOCAD': 'Калькулятор кабеля, база для AUTOCAD',
      'Меню Новости': 'Новости',
      'Меню Общее': 'Общее',
      'Предложения по импортозамещению': 'Предложения по импортозамещению',
      'Меню Вакансии': 'Вакансии',
      'График личного приема граждан':
        'График личного приема граждан, индивидуальных предпринимателей и представителей юридических лиц руководством ОАО Беларускабель',
      'График личного приема граждан меню': 'График личного приема граждан',
      'Противодействие коррупции': 'Противодействие коррупции',
      'Административные процедуры': 'Административные процедуры',
      'Меню Акционерам': 'Акционерам',
      'Меню Главная': 'Главная',

      // SLIDER

      'Кнопка Перейти': 'Перейти',

      'Лифтовые кабели и провода': 'ЛИФТОВЫЕ КАБЕЛИ И ПРОВОДА',
      'Лифтовые кабели и провода ОП': 'Для эксплуатации на пассажирских <br /> и грузовых лифтах общего назначения.',

      'Кабели контрольные': 'КАБЕЛИ КОНТРОЛЬНЫЕ',
      'Кабели контрольные ОП':
        'Для неподвижного присоединения к электрическим <br /> приборам, аппаратам, сборкам зажимов электрических <br /> распределительных устройств',

      'КАБЕЛИ УПРАВЛЕНИЯ': 'КАБЕЛИ УПРАВЛЕНИЯ <br /> И ПЕРЕДАЧИ ДАННЫХ',
      'КАБЕЛИ УПРАВЛЕНИЯ ОП':
        'Кабели предназначены для передачи сигналов к аппаратуре <br /> контроля, используются для дистанционного управления <br /> исполнительными механизмами управления и для <br /> межприборного соединения при напряжении до 1 КВ',

      'Продукция для электрической установки железнодорожной сигнализации, централизации и блокировки':
        'Продукция для электрической установки железнодорожной сигнализации, централизации и блокировки',

      'КАБЕЛИ И ПРОВОДА СВЯЗИ': 'КАБЕЛИ И ПРОВОДА СВЯЗИ',
      'КАБЕЛИ И ПРОВОДА СВЯЗИ ОП':
        'Используется для прокладки линий телефонной связи <br />и распределительных сетей',

      'КАБЕЛИ И ПРОВОДА': 'КАБЕЛИ И ПРОВОДА ДЛЯ <br /> СИГНАЛИЗАЦИИ И ВИДЕОНАБЛЮДЕНИЯ',
      'КАБЕЛИ И ПРОВОДА ОП':
        'Для монтажа систем сигнализации и эксплуатации в <br /> условиях стационарной и нестационарной прокладки',

      'КАБЕЛИ МОНТАЖНЫЕ': 'КАБЕЛИ И ПРОВОДА <br /> МОНТАЖНЫЕ',
      'КАБЕЛИ МОНТАЖНЫЕ ОП':
        'Предназначены для передачи электрической энергии в пределах <br /> одной электрической установки, электрического <br /> прибора или аппарата.',
      'Кабель для железной дороги': 'Кабель для железной дороги',
      'Кабели и провода для сигнализации и видеонаблюдения':
        'Кабели и провода для сигнализации и видеонаблюдения',
      'КАБЕЛИ И ПРОВОДА МОНТАЖНЫЕ': 'КАБЕЛИ И ПРОВОДА МОНТАЖНЫЕ',
      'Шапка Корзина': 'Корзина',

      // Advantages


      'Широкая производимая номенклатура кабельных изделий': 'Широкая производимая номенклатура кабельных изделий',
      'Наличие складской программы': 'Наличие складской программы',
      'Короткие сроки изготовления': 'Короткие сроки изготовления',
      'Высококвалифицированный персонал': 'Высококвалифицированный персонал',
      'Возможность доставки и самовывоза': 'Возможность доставки и самовывоза',
      'Выгодная стоимость': 'Выгодная стоимость',
      'Качество подтверждено сертификатами': 'Качество подтверждено сертификатами',
      'Сертифицированная система менеджмента качества': 'Сертифицированная система менеджмента качества',
      'Проведение испытаний 100% продукции': 'Проведение испытаний 100% продукции',
      'Своевременная модернизация и внедрение инноваций на производстве': 'Своевременная модернизация и внедрение инноваций на производстве',
      'История традиций по производству кабельной продукции с 1959 г': 'История традиций по производству кабельной продукции с 1959 г',

      'Розничная торговля от 1 метра, напрямую от производителя':
      'Розничная торговля от 1 метра, напрямую от производителя',
      'Интернет-магазин розничной торговли': 'Интернет-магазин розничной торговли',
      'Оптовые цены для юр лиц': 'Оптовые цены для юр лиц',
      'Запросить коммерческое предложение': 'Запросить коммерческое предложение',
      'История становления ОАО «Беларускабель»': 'История становления ОАО «Беларускабель»',
      'Познакомиться с организацией': 'Познакомиться с организацией',
      'Подбор продукции по характеристикам': 'Подбор продукции по характеристикам',
      'Калькулятор кабеля': 'Калькулятор кабеля',
      'Свободные остатки готовой продукции на складе':'Свободные остатки готовой продукции на складе',
      'Посмотреть остатки': 'Посмотреть остатки',

      // Search

      'Поиск по марке кабеля': 'Поиск по марке кабеля',
      'Кнопка Искать': 'Искать',

      // Production

      'Заг Продукция': 'Продукция',
      'Весь ассортимент': 'Весь ассортимент',

      // BANNER

      'ВЫСОКОЕ КАЧЕСТВО ГАРАНТИРОВАНО': 'ВЫСОКОЕ КАЧЕСТВО ГАРАНТИРОВАНО',
      'Вся продукция сертифицирована и производится в соответствии с ТУ и ГОСТ':
        'Вся продукция сертифицирована и производится в соответствии с ТУ и ГОСТ',

      // SECOND BANNER

      'Интернет магазин «Беларускабель»': 'Интернет магазин «Беларускабель»',
      'Покупайте кабель онлайн':
        'Покупайте кабель онлайн (от 1 метра) <br /> в фирменном магазине напрямую от производителя <br />',
      'Рассрочка от 3 месяцев': 'Рассрочка от 3 месяцев',
      'Оплата онлайн': 'Оплата онлайн',
      'Широкий ассортимент': 'Широкий ассортимент',
      'Выгодные цены': 'Выгодные цены',
      'Перейти в Интернет магазин': 'Перейти в Интернет магазин',

      // INDUSTRIES

      'Отрасли применения продукции': 'Отрасли применения продукции',

      // COUNTRIES

      'Страна Россия': 'Россия',
      'Страна Китай': 'Китай',
      'Страна Украина': 'Украина',
      'Страна Великобритания': 'Великобритания',
      'Страна Молдова': 'Молдова',
      'Страна Казахстан': 'Казахстан',
      'Страна Киргизия': 'Киргизия',
      'Страна Азербайджан': 'Азербайджан',
      'Страна Туркменистан': 'Туркменистан',
      'Страна Литва': 'Литва',
      'Страна Латвия': 'Латвия',
      'Страна Эстония': 'Эстония',
      'Страна Чехия': 'Чехия',
      'Страна Польша': 'Польша',
      'Страна Грузия': 'Грузия',
      'Страна Сербия': 'Сербия',
      'Страна Египет': 'Египет',
      'Страна Кипр': 'Кипр',
      'Страна Германия':'Германия',
      'Страна Панама':'Панама',
      'Страна Афганистан':'Афганистан',
      'Страна Болгария':'Болгария',
      'Страна Индия':'Индия',
      'Страна Иран':'Иран',
      'Страна Армения':'Армения',
      'Страна Хорватия':'Хорватия',
      'География поставок': 'География поставок',

      // ACHIEVEMENTS

      'Достижения и Сертификаты': 'Достижения и Сертификаты',
      'Заг Новости': 'Новости',
      'Все новости': 'Все новости',

      // THIRD BANNER

      'Заг Преимущества': 'Преимущества',
      'количество кабеля': '143 799 <span>км.</span>',
      'кабеля произведено в 2021 году': 'кабеля произведено в 2021 году',
      'переработано меди': '614 <span>тон</span>',
      'меди переработано за 2021 год': 'меди переработано за 2021 год',
      'колво человек': '420 <span>человек</span>',
      'среднесписочная численность': 'среднесписочная численность',
      'колво инновационной': '88 <span>ед.</span>',
      'инновационной продукции': 'инновационной продукции',
      'готовой продукции': '40 343 <span>км.</span>',
      'готовой продукции на складе': 'готовой продукции на складе',
      'колво оборудования': 'Более 20 <span>ед.</span>',
      'модернизированного оборудования': 'модернизированного оборудования',

      // PARTNERS

      'Интернет-портал Президента Республики Беларусь':
        'Интернет-портал Президента Республики Беларусь',
      'Национальный правовой интернет-портал Республики Беларусь':
        'Национальный правовой интернет-портал Республики Беларусь',
      'Мозырский районный исполнительный комитет': 'Мозырский районный исполнительный комитет',
      'Министерство промышленности Республики Беларусь':
        'Министерство промышленности Республики Беларусь',

      // FOOTER

      'Описание снизу': `Более 60 лет ОАО "Беларускабель" - одно из ведущих кабельных предприятий в
                Республике Беларусь, которое изготавливает и поставляет кабельную продукцию для
                отраслей и сфер потребления - таких как радиоэлектроника, приборостроение, связь,
                авиационная техника, строительство, телекоммуникация и электротехника. Мы всегда
                рады новым проектам и партнёрам! Изготавливаем кабель и провод по ГОСТу, принимаем
                заказы на разработку и производство по Вашим ТЗ и ТУ, всегда готовы предоставить
                консультацию по любым вопросам.`,

      // BREADCRUMBS
      'Меню Компания': 'Компания',

      // HISTORY PAGE
      'Предприятие ОАО': `<span>Предприятие ОАО «Беларускабель»</span> (ранее «Мозырькабель») организовано в
                  старейшем городе белорусского Полесья в 1959 г. На возвышенности у самой реки
                  Припять расположились корпуса завода. С момента образования предприятие считается
                  одним из лучших в кабельной отрасли СНГ.`,
      'За годы деятельности ОАО «Беларускабель»': `<span>За годы деятельности ОАО «Беларускабель»</span> значительно выросли
                производственные мощности, сформировался опытный коллектив, который решает серьезные
                инженерные задачи по постановке на производство новых кабельно-проводниковых изделий
                и освоению прогрессивной технологии. На предприятии был установлен электронный
                ускоритель, в то время одна из самых прогрессивных технологий, для облучения
                изоляции проводов, что существенно улучшило физико-механические характеристики
                проводов и позволило ставить на производство провода, используемые для авиационной
                промышленности`,
      'В настоящее время': `<span>В настоящее время</span> предприятие производит монтажные провода,
                терморадиационностойкие провода, малогабаритные и теплостойкие провода, силовые
                кабели и контрольные кабели, кабели связи, радиочастотные кабели, а также кабели и
                провода различного специального назначения с жилой из медной и алюминиевой
                проволоки. Сфера применения выпускаемых изделий охватывает такие отрасли народного
                хозяйства, как радиоэлектроника, авиационная техника, приборостроение,
                строительство, связь, телекоммуникация и электротехника.`,

      // ADMINISTRATIVE PROCEDURES

      'Делопроизводитель Дубинина Людмила': `Делопроизводитель Дубинина Людмила Сергеевна, кабинет «Канцелярия» на 1 этаже, телефон
            +375 (236) 37-25-08; <br /> Специалист по кадрам Щербин Светлана Ивановна, кабинет «Отдел
            кадров» на 1 этаже, телефон +375 (236) 37-25-66; <br /> Секретарь приемной Мартинович Богдана Руслановна, «Приемная» на 3 этаже, телефон +375 (236) 24-77-29; <br /> Главный
            бухгалтер Белько Анна Александровна, кабинет «Главного бухгалтера» приемная на 3 этаже,
            телефон +375 (236) 24-65-23; <br /> Председатель профкома Сидоренко Светлана Ивановна,
            телефон +375 (236) 25-40-64`,

      'ПРИЕМ ЗАЯВЛЕНИЙ ОСУЩЕСТВЛЯЕТСЯ ПО РАБОЧИМ ДНЯМ': `ПРИЕМ ЗАЯВЛЕНИЙ ОСУЩЕСТВЛЯЕТСЯ ПО РАБОЧИМ ДНЯМ <br /> С 08:00 ДО 12:45 И С 13:30 ДО
              17:00, <br /> ПЯТНИЦА С 08:00 ДО 12:45 И С 13:30 ДО 15:45.`,
      'Основные принципы': `Основные принципы осуществления административных процедур содержатся в
            <a href="http://www.pravo.by/document/?guid=3871&amp;p0=h10800433" target="_blank" rel="noreferrer noopener">
              Законе Республики Беларусь в Законе Республики Беларусь от 28.10.2008 года №433-
            </a>
            <a href="http://www.pravo.by/document/?guid=3871&amp;p0=h10800433" target="_blank" rel="noreferrer noopener">
              3 «Об основах административных процедур»
            </a>
            .`,
      'Административные процедуры, осуществляемые в отношении граждан, юридических лиц и индивидуальных предпринимателей, определяются следующими перечнями': `Административные процедуры, осуществляемые в отношении граждан, юридических лиц и
            индивидуальных предпринимателей, определяются следующими перечнями`,
      'перечень административных процедур': `
            перечень административных процедур, осуществляемых государственными органами и иными
            организациями по заявлениям граждан, утвержденный
            <a href="http://www.pravo.by/document/?guid=3871&amp;p0=P31000200" target="_blank" rel="noreferrer noopener">
              
              указом
            </a>`,
      'Президента Республики Беларусь': `
            <a href="http://www.pravo.by/document/?guid=3871&amp;p0=P31000200" target="_blank" rel="noreferrer noopener">
              Президента Республики Беларусь от 26.04.2010 № 200
            </a>
            ;`,
      'единый перечень административных процедур': `
            единый перечень административных процедур, осуществляемых государственными органами и
            иными организациями в отношении юридических лиц и индивидуальных предпринимателей,
            утвержденный
            <a href="http://pravo.by/document/?guid=3871&amp;p0=C21200156" target="_blank" rel="noreferrer noopener">
              
              постановлением Совета
            </a>`,
      'описание административных процедур': `<p>
              <a href="http://pravo.by/document/?guid=3871&amp;p0=C21200156" target="_blank" rel="noreferrer noopener">
                Министров Республики Беларусь от 17.02.2012 № 156
              </a>
              .
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <p>
              Перечень административных процедур, осуществляемых ОАО «Беларускабель» представлен в
              <a target="_blank" rel="noreferrer noopener" href="/upload/per-prots-pril-1.pdf">
                приложении № 1
              </a>
              .
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Порядок осуществления процедур</b>
            </h3>
            <p></p>
            <p>Закон Республики Беларусь от 28.10.2008 г. № 433-З</p>
            <p></p>
            <p>«Об основах административных процедур» (выписка)</p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <p>Статья 9. Компетенция уполномоченных органов</p>
            <p></p>
            <p>Уполномоченные органы при осуществлении административных процедур:</p>
            <p></p>
            <p>
              - обеспечивают доброжелательное, вежливое и внимательное отношение работников
              уполномоченного органа к заинтересованнымлицам;
            </p>
            <p></p>
            <p>
              - обеспечивают своевременное и надлежащее выполнение функций временно отсутствующих
              работников уполномоченных органов, осуществляющих прием заинтересованных лиц, другими
              работниками этихорганов;
            </p>
            <p></p>
            <p>
              - разъясняют заинтересованным лицам обязанность уполномоченных органов самостоятельно
              запрашивать документы и (или) сведения, необходимые для осуществления административных
              процедур, за исключением тех, которые включены в перечни документов и (или) сведений,
              представляемых заинтересованными лицами, а также права и обязанности
              заинтересованныхлиц;
            </p>
            <p></p>
            <p>
              - бесплатно предоставляют заинтересованным лицам предусмотренные законодательством об
              административных процедурах формы (бланки) документов, необходимых для обращения за
              осуществлением административных процедур, и разъясняют порядок их заполнения и
              представления;
            </p>
            <p></p>
            <p>
              - запрашивают от других государственных органов, иных организаций документы и (или)
              сведения, необходимые для осуществления административныхпроцедур;
            </p>
            <p></p>
            <p>
              - предоставляют заинтересованным лицам возможность знакомиться с материалами,
              связанными с рассмотрением своих заявлений, в том числе с документами и (или)
              сведениями, полученными этими органами от других государственных органов, иных
              организаций и необходимыми для осуществления административных процедур, делать из них
              выписки, если иное не предусмотрено законодательством о государственных секретах,
              коммерческой или иной охраняемой закономтайне;
            </p>
            <p></p>
            <p>- уведомляют заинтересованных лиц о принятых административныхрешениях;</p>
            <p></p>
            <p>
              - разъясняют заинтересованным лицам порядок и сроки обжалования принятых
              административныхрешений;
            </p>
            <p></p>
            <p>
              - осуществляют другие полномочия, предусмотренные настоящим Законом и иными актами
              законодательства об административных процедурах.
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Статья 10. Права заинтересованных лиц</b>
            </h3>
            <p></p>
            <p>Заинтересованные лица имеют право:</p>
            <p></p>
            <p>- обращаться с заявлениями в уполномоченныеорганы;</p>
            <p></p>
            <p>
              - бесплатно получать от уполномоченных органов предусмотренные законодательством об
              административных процедурах формы (бланки) документов, необходимых для обращения за
              осуществлением административныхпроцедур;
            </p>
            <p></p>
            <p>- получать от уполномоченных органов разъяснение своих прав иобязанностей;</p>
            <p></p>
            <p>
              - принимать участие в административных процедурах лично и (или) через своих
              представителей, если иное не предусмотрено законодательнымиактами;
            </p>
            <p></p>
            <p>
              - знакомиться с материалами, связанными с рассмотрением своих заявлений, делать из них
              выписки, если иное не предусмотрено законодательством о государственных секретах,
              коммерческой или иной охраняемой закономтайне;
            </p>
            <p></p>
            <p>- получать административные решения либо выписки изних;</p>
            <p></p>
            <p>
              - отозвать свое заявление в любое время до окончания осуществления административной
              процедуры;
            </p>
            <p></p>
            <p>- обжаловать принятые административныерешения;</p>
            <p></p>
            <p>
              - осуществлять другие права, предусмотренные настоящим Законом и иными актами
              законодательства об административных процедурах.
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Статья 11. Обязанности заинтересованных лиц</b>
            </h3>
            <p></p>
            <p>Заинтересованные лица обязаны:</p>
            <p></p>
            <p>
              - вежливо относиться к работникам уполномоченных органов, не допускать нецензурных
              либо оскорбительных слов или выражений в отношении такихработников;
            </p>
            <p></p>
            <p>
              - представлять в уполномоченные органы документы и (или) сведения, включенные в
              перечни документов и (или) сведений, представляемых заинтересованными лицами, а также
              документы, указанные в абзацах втором - седьмом пункта 2 статьи 15 настоящего Закона,
              в случае истребования такихдокументов;
            </p>
            <p></p>
            <p>- вносить плату, взимаемую при осуществлении административныхпроцедур;</p>
            <p></p>
            <p>
              - своевременно информировать уполномоченные органы об изменении места жительства
              (места пребывания), места нахождения в период осуществления административнойпроцедуры;
            </p>
            <p></p>
            <p>
              - выполнять другие обязанности, предусмотренные настоящим Законом и иными актами
              законодательства об административных процедурах.
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Статья 30. Порядок обжалования административного решения</b>
            </h3>
            <p></p>
            <p>
              1.Заинтересованное лицо обладает правом на обжалование административного решения в
              административном (внесудебном)порядке.
            </p>
            <p></p>
            <p>
              2.Административная жалоба направляется в государственный орган, иную организацию,
              вышестоящие по отношению к уполномоченному органу, принявшему административное
              решение, либо в государственный орган, иную организацию, к компетенции которых в
              соответствии с законодательными актами относится рассмотрение таких жалоб (далее -
              орган, рассматривающий жалобу).
            </p>
            <p></p>
            <p>
              3.Обжалование административного решения в судебном порядке осуществляется после
              обжалования такого решения в административном (внесудебном) порядке, если иной порядок
              обжалования не предусмотрен законодательнымиактами.
            </p>
            <p></p>
            <p>
              Обжалование административного решения в судебном порядке осуществляется в соответствии
              с гражданским процессуальным или хозяйственным процессуальным законодательством.
            </p>
            <p></p>
            <p>
              4.Подача жалобы в административном (внесудебном) порядке не лишает заинтересованное
              лицо права на обращение с жалобой всуд.
            </p>`,

      // AGAINST CORRUPTION

      'Противодействие коррупции': 'Противодействие коррупции',
      'Описание противодействия коррупции': `<p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H11500305"
              target="_blank"
              rel="noreferrer noopener"
            >
              Закон Республики Беларусь от 15 июля 2015 г. № 305-3 «О борьбе с коррупцией»
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H10300204"
              target="_blank"
              rel="noreferrer noopener"
            >
              Закон Республики Беларусь от 14 июня 2003 г. № 204-З «О государственной службе в
              Республике Беларусь»
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H11400122"
              target="_blank"
              rel="noreferrer noopener"
            >
              Закон Республики Беларусь от 04 января 2014 г. № 122-З «Об основах деятельности по
              профилактике правонарушений»
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H10300174"
              target="_blank"
              rel="noreferrer noopener"
            >
              Закон Республики Беларусь от 4 января 2003 г. № 174-З «О декларировании физическими
              лицами доходов и имущества»
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=HK9900275"
              target="_blank"
              rel="noreferrer noopener"
            >
              Уголовный кодекс Республики Беларусь от 9 июля 1999 г. № 275-З
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=Hk0300194"
              target="_blank"
              rel="noreferrer noopener"
            >
              Кодекс Республики Беларусь об административных правонарушениях от 21 апреля 2003 г. №
              194-З
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="/upload/plan_meropriyatiy_po_borbe_s_korruptsiey_2020-2022.docx"
            >
              План мероприятий по противодействию коррупции в ОАО «Беларускабель» на 2020-2022 годы
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/file_kor_01.pdf">
              О предотвращении и урегулировании конфликтов интересов
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/polozhenie_komisii_po_kor.pdf">
              Положение о комиссии по противодействию коррупции ОАО Беларускабель
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/polozhenie_o_zakupkah.pdf">
              Положение о закупках товаров (работ, услуг) за счёт собственных средств
            </a>
          </p>
          <p>
            <br />
          </p>
          <p>
            Примечание: «При наличии признаков коррупционных правонарушений или правонарушений,
            создающих условия для коррупции (статьи 25 и 37 Закона Республики Беларусь «О борьбе с
            коррупцией»), к административным правонарушениям, связанным с коррупцией, могут быть
            отнесены правонарушения, предусмотренные статьями 9.6, 9.13, 9.26, 10.5, 11.16, 11.77,
            23.81, 23.82, 23.83, 23.84 Кодекса Республики Беларусь об административных
            правонарушениях».
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=HK9900296"
              target="_blank"
              rel="noreferrer noopener"
            >
              Трудовой кодекс Республики Беларусь от 26 июля 1999 г. № 296-З
            </a>
          </p>
          <p>
            Примечание: «Трудовым кодексом Республики Беларусь установлены:
            <br />в статье 27 - ограничения совместной работы в государственных организациях (их
            обособленных подразделениях) супругов, близких родственников и свойственников;
          </p>
          <p>
            в пункте 5 статьи 47 – право нанимателя на прекращение трудового договора с
            государственным должностным лицом за неподписание обязательства по соблюдению
            антикоррупционных ограничений;
          </p>
          <p>
            в пункте 5-1 статьи 47 – право нанимателя на прекращение трудового договора с
            государственным должностным лицом за нарушение обязательства по соблюдению
            антикоррупционных ограничений, а также за совершение правонарушения, создающего условия
            для коррупции, или коррупционного правонарушения;
          </p>
          <p>
            в статье 255 – ограничения работы по совместительству для руководителей государственных
            организаций и организаций, в уставных фондах которых 50 и более процентов акций (долей)
            находятся в государственной собственности, а также запрет для руководителей организаций
            любых форм собственности входить в состав органов, осуществляющих функции надзора и
            контроля в соответствующих организациях».
          </p>
          <p>&nbsp;</p>
          <h2>ЛОКАЛЬНЫЕ ПРАВОВЫЕ АКТЫ МИНИСТЕРСТВА </h2>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/plan_meropriyatij_korupcii2016_2017.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              План мероприятия по противодействию коррупции в системе Министерства промышленности
              Республики Беларусь на 2018-2019 годы
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/prikaz_505.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Приказ Министерства промышленности Республики Беларусь от 29 декабря 2017 г. №505 "О
              внесении изменения в приказ Министерства промышленности Республики Беларусь от 26
              января 2012 г. №49"
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/sostav_kom_2014.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Приказ Министерства промышленности Республики Беларусь от 27 марта 2014 г. № 132 «О
              внесении изменений в приказ Министерства промышленности Республики Беларусь» (внесены
              изменения в приказ от 26 января 2012 г. № 49, которыми определен новый состав комиссии
              по противодействию коррупции)
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/nov_pol_kom.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Приказ Министерства промышленности Республики Беларусь от 21 августа 2015 г. № 407 «О
              внесении дополнений и изменений в приказ Министерства промышленности Республики
              Беларусь от 26 января 2012 г. № 49» (утверждена новая редакция Положения о комиссии по
              противодействию коррупции Министерства промышленности Республики Беларусь)
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/lokalnue_pravovue_aktu"
              target="_blank"
              rel="noreferrer noopener"
            >
              Локальные правовые акты Министерства
            </a>
          </p>
          <p>
            <br />
          </p>
          <h2>АНТИКОРРУПЦИОННЫЕ МЕРОПРИЯТИЯ </h2>
          <p>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="/upload/plan_meropriyatiy_po_borbe_s_korruptsiey_2020-2022.docx"
            >
              План мероприятий по противодействию коррупции в ОАО «Беларускабель» на 2020-2022 годы
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/tip_pravila.pdf">
              Типовые правила антикоррупционного поведения в государственных органах и иных
              организациях Республики Беларусь
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/polow_o_komisii_2.pdf">
              Положение о комиссии по противодействию коррупции ОАО Беларускабель
            </a>
          </p>`,

      'Наша продукция отмечена': `Наша продукция отмечена свидетельствами, грамотами и дипломами международных и
              региональных выставок, ярмарок и конкурсов последних лет, также заводу были
              неоднократно присуждены различные призы - за качество, положительный имидж, за лучшую
              торговую марку.`,
      'ОАО «Беларускабель» на постоянной основе участвует':`ОАО «Беларускабель» на постоянной основе участвует 
              в конкурсе «Лучшие товары Республики Беларусь», который проводится ежегодно, начиная с 2002 года, 
              по совместной инициативе Госстандарта, областных исполнительных комитетов и Мингорисполкома. В процессе 
              проведения конкурса были заявлены различные виды кабельно-проводниковой продукции ОАО «Беларускабель»:`,
      '100 лучших товаров России':`В 2024 году ОАО «Беларускабель» очередной раз удалось подтвердить звание лауреата во Всероссийском конкурсе «100 лучших товаров России». Продукция ОАО «Беларускабель» - провода бортовые облученные с двухслойной изоляцией (БПДО, БПДОЭ, БПДОУ, БПДОУЭ) стала победителем в номинации Лучшие товары Республики Беларусь на рынке Российской Федерации производственно-технического назначения.`,
      'Согласно Постановлению от 3 апреля 2020 года':`Согласно Постановлению от 3 апреля 2020 года №203 ОАО «Беларускабель» присуждена Премия Правительства 
              Республики Беларусь за достижения в области качества за 2019 год (на 3 года).`,
      'единственный в Республике Беларусь':`ОАО «Беларускабель» - единственный в Республике Беларусь 
              кабельный завод, предоставляющий услуги по технической приемке продукции. На ОАО «Беларускабель» дислоцируется Техническая 
              приемка №43 АО «РТ-Техприемка», специализированная организация Госкорпорации «Ростех» (ранее АО «Авиатехприемка») по оценке 
              соответствия продукции в форме контроля качества и технической приемке, согласно Положению от 22.10.2007 «О порядке проведения 
              контроля качества материалов и полуфабрикатов, используемых в производстве вооружения, военной, авиационной и специальной техники».`,
      'ОАО «Беларускабель» входит в состав организаций':`ОАО «Беларускабель» входит в состав организаций Ассоциации «Электрокабель», подписавших 
              Декларацию качества, принятую на общем собрании 16 апреля 2008. Также организация является членом Ассоциации предприятий 
              кабельной промышленности «Белкабель».`,
      '- «Лучшие товары Республики Беларусь» 2024 год НОВИНКА ГОДА - кабель плоский лифтовой гибкий комбинированный марки КПЛГК;':`- «Лучшие товары Республики Беларусь» 2024 год НОВИНКА ГОДА - кабель плоский лифтовой гибкий комбинированный марки КПЛГК;`,
      '- «Лучшие товары Республики Беларусь» 2023 год НОВИНКА ГОДА - кабель для сигнализации и блокировки, гибкий СБМВ;':`- «Лучшие товары Республики Беларусь» 2023 год НОВИНКА ГОДА - кабель для сигнализации и блокировки, гибкий СБМВ;`,
      '- «Лучшие товары Республики Беларусь» 2022 год НОВИНКА ГОДА - провод барьерный электризуемый БЭ-БЭП.000;':`- «Лучшие товары Республики Беларусь» 2022 год НОВИНКА ГОДА - провод барьерный электризуемый БЭ-БЭП.000;`,
      '- «Лучшие товары Республики Беларусь» 2021 год - вилка опрессованная 83-16-250-60227 IEC 53;':`- «Лучшие товары Республики Беларусь» 2021 год - вилка опрессованная 83-16-250-60227 IEC 53;`,
      '- «Лучшие товары Республики Беларусь» 2020 год НОВИНКА ГОДА - кабель силовой гибкий КГВЭВнг(А);':`- «Лучшие товары Республики Беларусь» 2020 год НОВИНКА ГОДА - кабель силовой гибкий КГВЭВнг(А);`,
      '- «Лучшие товары Республики Беларусь» 2019 год НОВИНКА ГОДА - кабель плоский лифтовой гибкий;':`- «Лучшие товары Республики Беларусь» 2019 год НОВИНКА ГОДА - кабель плоский лифтовой гибкий;`,
      '- «Лучшие товары Республики Беларусь» 2018 год НОВИНКА ГОДА - кабель контроля и передачи данных «Арктический КУПД»;':`- «Лучшие товары Республики Беларусь» 2018 год НОВИНКА ГОДА - кабель контроля и передачи данных «Арктический КУПД»;`,
      '- «Лучшие товары Республики Беларусь» 2017 год НОВИНКА ГОДА - кабель для систем управления и сигнализации, не распространяющий горения, с изоляцией и оболочкой из полимерных композиций, не содержащих галогенов КПЭПнг(А)-FRHF;':`- «Лучшие товары Республики Беларусь» 2017 год НОВИНКА ГОДА - кабель для систем управления и сигнализации, не распространяющий горения, с изоляцией и оболочкой из полимерных композиций, не содержащих галогенов КПЭПнг(А)-FRHF;`,
      '- и ранее.':`- и ранее.`,          

      'ОАО «Беларускабель» на постоянной основе': `ОАО «Беларускабель» на постоянной основе участвует
                в конкурсе «Лучшие товары Республики Беларусь», который проводится ежегодно, начиная с 2002
                года,по совместной инициативе Госстандарта, областных исполнительных комитетов и Мингорисполкома.
                В процессе проведения конкурса были заявлены различные виды кабельно-проводниковой продукции ОАО «Беларускабель»:`,

      'Заг Наименование': 'Наименование',
      'Заг Масса': 'Масса',
      'Заг Количество': 'Количество',
      'Номинальное сечение жил': 'Номинальное сечение жил, мм² -',
      'Число жил': 'Число жил',
      'Удалить из корзины': 'Удалить из корзины',
      'Цена по запросу': 'Цена по запросу',
      'Оформить заказ': 'Оформить заказ',
      'Продолжить покупки': 'Продолжить покупки',
      'На данный момент ваша корзина пуста.': 'На данный момент ваша корзина пуста.',
      'В каталоге вы можете найти интересующие вас': 'В каталоге вы можете найти интересующие вас',
      'Перейти в каталог': 'Перейти в каталог',
      'allCategories': 'Все категории'
    },
  },
  en: {
    translation: {
      'Политика в отношении обработки персональных данных': 'Policy regarding the processing of personal data',
      'Политика видеонаблюдения':'CCTV Policy',
      'Весь ассортимент': 'Whole range',
      'Нам доверяют': 'We are trusted',
      'Шапка Корзина': 'Cart',
      // HEADER
      'Производим кабельную продукцию c 1959 года': 'Producing cable products since 1959',
      'г. Мозырь, ул. Октябрьская, 14.': 'Mazyr, st. Kastrychnitskaya, 14.',
      'номер приёмной': 'Reception room',
      'отдел продаж': 'Sales department',
      'Мы в социальных сетях': 'We are in social networks',
      'Фирменный магазин': 'Brand store',      
      // 'Оставить заявку': 'Submit your application',
      'Войти в аккаунт': 'Sign in',
      'Меню каталог': 'Catalog',
      'Меню производство': 'Production',
      'Менеджмент качества': 'Quality management',
      'Центральная заводская лаборатория': 'Central factory laboratory',
      'Инновационная продукция': 'Innovative products',
      'Меню О компании': 'About company',
      'Меню История': 'History',
      'Меню Руководство': 'Management contact phone numbers',
      'СМИ о нас': 'Mass Media about us',
      'Меню Фотогалерея': 'Photo gallery',
      'Меню Дилеры': 'Dealers',
      'Меню Кибербезопасность': 'Cybersecurity',
      'Наши достижения': 'Our achievements',
      'Меню Контакты': 'Contacts',
      'Электронный каталог': 'Digital catalogue',
      'Предложения по импортозамещению': 'Proposals for cooperation',
      'Калькулятор кабеля, база для AUTOCAD': 'Cable calculator, base for AUTOCAD',
      'Меню Новости': 'News',
      'Меню Общее': 'General',
      'Предложения по импортозамещению': 'Proposals for import substitution',
      'Меню Вакансии': 'Vacancies',
      'График личного приема граждан':
        'Schedule of personal reception of citizens, individual entrepreneurs and representatives of legal entities by the management of Belaruskabel OJSC',

      'График личного приема граждан меню': 'Schedule of personal reception of citizens',
      'Противодействие коррупции': 'Anti-corruption',
      'Административные процедуры': 'Administrative procedures',
      'Меню Акционерам': 'Shareholders',
      'Меню Главная': 'Home',

      // SLIDER

      'Кнопка Перейти': 'Go',

      'КАБЕЛИ СИЛОВЫЕ': 'POWER CABLES',
      'КАБЕЛИ СИЛОВЫЕ ОП':
        'For transmission of electric power by currents of industrial <br /> frequencies and for fixed laying.',

      'Лифтовые кабели и провода': 'LIFT CABLES AND WIRES',
      'Лифтовые кабели и провода ОП': 'LIFT GENERAL PURPOSE CABLES AND WIRES',

      'Кабели контрольные': 'CONTROL CABLES',
      'Кабели контрольные ОП':
        'For fixed connection to electrical <br /> devices, apparatuses, terminal assemblies of electrical <br /> switchgears',

      'КАБЕЛИ УПРАВЛЕНИЯ': 'CONTROL AND DATA CABLES',
      'КАБЕЛИ УПРАВЛЕНИЯ ОП':
        'Cables are intended for transmission of signals to <br /> control equipment, used for remote control of <br /> actuators and for <br /> inter-device connection at voltage up to 1 kV',

      'Продукция для электрической установки железнодорожной сигнализации, централизации и блокировки':
        'Products for the electrical installation of railway signaling, interlocking and blocking',

      'КАБЕЛИ И ПРОВОДА СВЯЗИ': 'COMMUNICATION CABLES AND WIRES',
      'КАБЕЛИ И ПРОВОДА СВЯЗИ ОП': 'Used for laying telephone lines<br />and distribution networks',

      'КАБЕЛИ И ПРОВОДА': 'CABLES AND WIRES FOR <br /> ALARM AND VIDEO SURVEILLANCE',
      'КАБЕЛИ И ПРОВОДА ОП':
        'For installation of alarm systems and operation in <br /> conditions of stationary and non-stationary laying',

      'КАБЕЛИ МОНТАЖНЫЕ': 'CABLES AND WIRES <br /> INSTALLATION',
      'КАБЕЛИ МОНТАЖНЫЕ ОП':
        'Designed for transmission of electrical energy within <br /> one electrical installation, electrical <br /> device or apparatus.',
      'Кабель для железной дороги': 'Railway cable',
      'Кабели и провода для сигнализации и видеонаблюдения':
        'Cables and wires for alarm and video surveillance',
      'КАБЕЛИ И ПРОВОДА МОНТАЖНЫЕ': 'INSTALLATION CABLES AND WIRES',

      // Advantages
      'Широкая производимая номенклатура кабельных изделий': 'Wide range of manufactured cable products',
      'Наличие складской программы': 'Warehouse program',
      'Короткие сроки изготовления': 'Shortest period of time to manufacture',
      'Высококвалифицированный персонал': 'Highly qualified staff',
      'Возможность доставки и самовывоза': 'Delivery and self-delivery capabilities',
      'Выгодная стоимость': 'Reasonable cost',
      'Качество подтверждено сертификатами': 'Quality is confirmed by certificates',
      'Сертифицированная система менеджмента качества': 'Certified quality management system',
      'Проведение испытаний 100% продукции': 'Product testing',
      'Своевременная модернизация и внедрение инноваций на производстве': 'Modernization and innovation implementation',
      'История традиций по производству кабельной продукции с 1959 г': 'Cable production history begins in 1959',

      'Розничная торговля от 1 метра, напрямую от производителя':
        'Retail from 1 meter, directly from the manufacturer',
      'Интернет-магазин розничной торговли': 'Online retail store',
      'Оптовые цены для юр лиц': 'Wholesale prices for legal entities',
      'Запросить коммерческое предложение': 'Request a quote',
      'История становления ОАО «Беларускабель»':
        'The history of the formation of JSC "Belaruskabel"',
      'Познакомиться с организацией': 'Get to know the organization',
      'Подбор продукции по характеристикам': 'Selection of products according to characteristics',
      'Калькулятор кабеля': 'Cable calculator',
      'Свободные остатки готовой продукции на складе':'Available remainders of finished products in stock',
      'Посмотреть остатки': 'View remainders',

      // Search

      'Поиск по марке кабеля': 'Search by cable brand',
      'Кнопка Искать': 'Search',

      // PRODUCTION
      'Заг Продукция': 'Products',
      // BANNER

      'ВЫСОКОЕ КАЧЕСТВО ГАРАНТИРОВАНО': 'HIGH QUALITY GUARANTEED',
      'Вся продукция сертифицирована и производится в соответствии с ТУ и ГОСТ':
        'All products are certified and manufactured in accordance with TU and GOST',

      // SECOND BANNER

      'Интернет магазин «Беларускабель»': 'Online store "Belaruskabel"',
      'Покупайте кабель онлайн':
        'Buy the cable online (from 1 meter) <br /> in the company store directly from the manufacturer <br />',
      'Рассрочка от 3 месяцев': 'Installment from 3 months',
      'Оплата онлайн': 'Online payment',
      'Широкий ассортимент': 'A wide range of',
      'Выгодные цены': 'Favorable prices',
      'Перейти в Интернет магазин': 'Go to online store',

      // INDUSTRIES

      'Отрасли применения продукции': 'Product Industries',

      // COUNTRIES

      'Страна Россия': 'Russia',
      'Страна Китай': 'China',
      'Страна Украина': 'Ukraine',
      'Страна Великобритания': 'Great Britain',
      'Страна Молдова': 'Moldova',
      'Страна Казахстан': 'Kazakhstan',
      'Страна Киргизия': 'Kyrgyzstan',
      'Страна Азербайджан': 'Azerbaijan',
      'Страна Туркменистан': 'Turkmenistan',
      'Страна Литва': 'Lithuania',
      'Страна Латвия': 'Latvia',
      'Страна Эстония': 'Estonia',
      'Страна Чехия': 'Czech',
      'Страна Польша': 'Poland',
      'Страна Грузия': 'Georgia',
      'Страна Сербия': 'Serbia',
      'Страна Египет': 'Egypt',
      'Страна Кипр': 'Cyprus',
      'Страна Германия':'Germany',
      'Страна Панама':'Panama',
      'Страна Афганистан':'Afghanistan',
      'Страна Болгария':'Bulgaria',
      'Страна Индия':'India',
      'Страна Иран':'Iran',
      'Страна Армения':'Armenia',
      'Страна Хорватия':'Croatia',
      'География поставок': 'Geography of deliveries',

      // ACHIEVEMENTS

      'Достижения и Сертификаты': 'Achievements and Certificates',
      'Заг Новости': 'News',
      'Все новости': 'All news',

      // THIRD BANNER

      'Заг Преимущества': 'Advantages',
      'количество кабеля': '143 799 <span>km.</span>',
      'кабеля произведено в 2021 году': 'cables produced in 2021',
      'переработано меди': '614 <span>tone</span>',
      'меди переработано за 2021 год': 'copper recycled in 2021',
      'колво человек': '420 <span>people</span>',
      'среднесписочная численность': 'average headcount',
      'колво инновационной': '88 <span>unit</span>',
      'инновационной продукции': 'innovative products',
      'готовой продукции': '40 343 <span>km.</span>',
      'готовой продукции на складе': 'finished products in stock',
      'колво оборудования': 'More than 20 <span>units</span>',
      'модернизированного оборудования': 'upgraded equipment',

      // PARTNERS

      'Интернет-портал Президента Республики Беларусь':
        'Internet portal of the President of the Republic of Belarus',
      'Национальный правовой интернет-портал Республики Беларусь':
        'National Legal Internet Portal of the Republic of Belarus',
      'Мозырский районный исполнительный комитет': 'Mozyr regional executive committee',
      'Министерство промышленности Республики Беларусь':
        'Ministry of Industry of the Republic of Belarus',

      // FOOTER

      'Описание снизу': `For more than 60 years Belaruskabel JSC has been the leading 
      cable enterprise in the Republic of Belarus, which manufactures and supplies 
      cable products for industries and consumer areas, such as radio electronics, 
      instrumentation, communications, aviation technology, construction, telecommunications, 
      electrical engineering, oil and gas, energy, radio engineering; nuclear power plant; 
      automotive industry; railway industry, as well as military-industrial complex enterprises. 
      We always welcome new projects and partners! 
      We manufacture cable and wire according to GOST and we accept orders for development and production according to your technical specifications. 
      We are always ready to provide advice on any issue.`,

      // BREADCRUMBS
      'Меню Компания': 'Company',

      // HISTORY PAGE
      'Предприятие ОАО': `<span>JSC "Belaruskabel"</span> (formerly "Mozyrkabel") was organized in the oldest city of the Belarusian Polesye in 1959. 
        The buildings of the plant were sited on the hillside of the Pripyat River. The company is considered one of the best in the CIS cable industry.`,
      'За годы деятельности ОАО «Беларускабель»': `<span>Over these years JSC "Belaruskabel"</span> has considerably increased its output, 
      its personnel is capable of solving sophisticated engineering problems in the field of cable making and up-to-date technology for such operations. <br>
      An electronic accelerator has been installed at the enterprise, at that time one of the most advanced technologies for irradiating wire insulation, 
      which significantly improved the physical and mechanical characteristics of wires and made it 
      possible to put into production wires used for the aviation industry.`,
      'В настоящее время': `Today it is a modern, universal enterprise equipped for production of mounting heat-and-radiation resistant and heat-resistant wires, 
      radiofrequency cables, power and control cables, armoured and unarmoured, control and data cables, 
      radio and communication cables, installation wires and various special purpose wires with copper and aluminium core, 
      fire-resistant cables and wires with FRLS, FRHF index.<br>
      For more than 60 years Belaruskabel JSC has been the leading cable enterprise in the Republic of Belarus, 
      which manufactures and supplies cable products for industries and consumer areas, such as radio electronics, instrumentation, 
      communications, aviation technology, construction, telecommunications, electrical engineering, oil and gas, energy, radio engineering; 
      nuclear power plant; automotive industry; railway industry, as well as military- industrial complex enterprises.`,

      // ADMINISTRATIVE PROCEDURES

      'Делопроизводитель Дубинина Людмила': `Clerk Dubinina Lyudmila Sergeevna, office "Office" on the 1st floor, telephone
             +375 236 37 25 08; <br /> HR Specialist Svetlana Ivanovna Shcherbin, office “Department
             staff” on the 1st floor, phone +375 236 37 25 66; <br /> Receptionist Martsinovich Bohdana Ruslanovna, "Reception" on the 3rd floor, phone +375 236 24 77 29; <br />
             accountant Belko Anna Alexandrovna, office of the "Chief Accountant" reception on the 3rd floor,
             phone +375 236 24 65 23; <br /> Chairman of the trade union committee Sidorenko Svetlana Ivanovna,
             phone +375 236 25 40 64`,

      'ПРИЕМ ЗАЯВЛЕНИЙ ОСУЩЕСТВЛЯЕТСЯ ПО РАБОЧИМ ДНЯМ': `APPLICATIONS ARE ACCEPTED ON WORKING DAYS <br /> FROM 08:00 TO 12:45 AND FROM 13:30 TO
               17:00, <br /> FRIDAY from 08:00 to 12:45 and from 13:30 to 15:45.`,
      'Основные принципы': `The basic principles for the implementation of administrative procedures are contained in the 
             <a href="http://www.pravo.by/document/?guid=3871&amp;p0=h10800433" target="_blank" rel="noreferrer noopener">
               Law of the Republic of Belarus in the Law of the Republic of Belarus dated October 28, 2008 No. 433-
             </a>
             <a href="http://www.pravo.by/document/?guid=3871&amp;p0=h10800433" target="_blank" rel="noreferrer noopener">
               3 "On the basics of administrative procedures"
             </a>
             .`,
      'Административные процедуры, осуществляемые в отношении граждан, юридических лиц и индивидуальных предпринимателей, определяются следующими перечнями': `Administrative procedures carried out in relation to citizens, legal entities and
             individual entrepreneurs are determined by the following lists`,
      'перечень административных процедур': `
            a list of administrative procedures carried out by state bodies and other
             organizations at the request of citizens, approved
             <a href="http://www.pravo.by/document/?guid=3871&amp;p0=P31000200" target="_blank" rel="noreferrer noopener">
               
               decree
             </a>`,
      'Президента Республики Беларусь': `
            <a href="http://www.pravo.by/document/?guid=3871&amp;p0=P31000200" target="_blank" rel="noreferrer noopener">
               of the President of the Republic of Belarus dated April 26, 2010 No. 200
             </a>
             ;`,
      'единый перечень административных процедур': `
            a unified list of administrative procedures carried out by state bodies and
             other organizations in relation to legal entities and individual entrepreneurs,
             approved
             <a href="http://pravo.by/document/?guid=3871&amp;p0=C21200156" target="_blank" rel="noreferrer noopener">
               
               Council decision
             </a>`,
      'описание административных процедур': `<p>
              <a href="http://pravo.by/document/?guid=3871&amp;p0=C21200156" target="_blank" rel="noreferrer noopener">
                Ministers of the Republic of Belarus dated February 17, 2012 No. 156
              </a>
              .
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <p>
              The list of administrative procedures carried out by Belaruskabel OJSC is presented in 
              <a target="_blank" rel="noreferrer noopener" href="/upload/per-prots-pril-1.pdf">
                application number 1
              </a>
              .
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Order of procedures</b>
            </h3>
            <p></p>
            <p>Law of the Republic of Belarus dated October 28, 2008 No. 433-З</p>
            <p></p>
            <p>“On the Basics of Administrative Procedures” (excerpt)</p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <p>Article 9. Competence of authorized bodies</p>
            <p></p>
            <p>Competent authorities in the implementation of administrative procedures:</p>
            <p></p>
            <p>
              - provide friendly, polite and attentive attitude of employees
              authorized body to interested persons;
            </p>
            <p></p>
            <p>
              - ensure the timely and proper performance of the functions of temporarily absent
              employees of authorized bodies that receive interested persons, other
              employees of these bodies;
            </p>
            <p></p>
            <p>
              - explain to interested persons the obligations of authorized bodies on their own
              request documents and (or) information necessary for the implementation of administrative
              procedures, with the exception of those included in the lists of documents and (or) information,
              represented by interested parties, as well as the rights and obligations
              interested persons;
            </p>
            <p></p>
            <p>
              - free of charge provide interested persons with the provisions of the legislation on
              administrative procedures forms (blanks) of documents required for applying for
              implementation of administrative procedures, and explain the procedure for their completion and
              representation;
            </p>
            <p></p>
            <p>
              - request from other state bodies, other organizations documents and (or)
              information necessary for the implementation of administrative procedures;
            </p>
            <p></p>
            <p>
              - provide interested persons with the opportunity to get acquainted with the materials,
              related to the consideration of their applications, including documents and (or)
              information received by these bodies from other state bodies, other
              organizations and necessary for the implementation of administrative procedures, make them
              extracts, unless otherwise provided by the legislation on state secrets,
              commercial or other legally protected secret;
            </p>
            <p></p>
            <p>- notify interested parties of the administrative decisions taken;</p>
            <p></p>
            <p>
              - explain to interested parties the procedure and terms for appealing against adopted
              administrative decisions;
            </p>
            <p></p>
            <p>
              - exercise other powers provided for by this Law and other acts
              legislation on administrative procedures.
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Article 10. Rights of interested parties</b>
            </h3>
            <p></p>
            <p>Interested persons have the right to:</p>
            <p></p>
            <p>- apply to the authorized bodies;</p>
            <p></p>
            <p>
              - receive free of charge from the authorized bodies the provisions of the legislation on
              administrative procedures forms (blanks) of documents required for applying for
              implementation of administrative procedures;
            </p>
            <p></p>
            <p>- receive from the authorized bodies an explanation of their rights and obligations;</p>
            <p></p>
            <p>
              - take part in administrative procedures personally and (or) through their
              representatives, unless otherwise provided by legislative acts;
            </p>
            <p></p>
            <p>
              - get acquainted with the materials related to the consideration of their applications, make them
              extracts, unless otherwise providedon legislation on state secrets,
              commercial or other legally protected secret;
            </p>
            <p></p>
            <p>- receive administrative decisions or extracts from them;</p>
            <p></p>
            <p>
              - withdraw your application at any time before the end of the administrative
              procedures;
            </p>
            <p></p>
            <p>- appeal against the administrative decisions taken;</p>
            <p></p>
            <p>
              - to exercise other rights provided for by this Law and other acts
              legislation on administrative procedures.
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Article 11. Obligations of interested parties</b>
            </h3>
            <p></p>
            <p>Stakeholders are required to:</p>
            <p></p>
            <p>
              - be polite to employees of authorized bodies, not allow obscene
              or offensive words or expressions against such workers;
            </p>
            <p></p>
            <p>
              - submit to the authorized bodies the documents and (or) information included in the
              lists of documents and (or) information submitted by interested parties, as well as
              the documents specified in paragraphs two - seven of paragraph 2 of Article 15 of this Law,
              if such documents are requested;
            </p>
            <p></p>
            <p>- pay the fee charged for the implementation of administrative procedures;</p>
            <p></p>
            <p>
              - timely inform the authorized bodies about the change of place of residence
              (places of stay), places of stay during the implementation of the administrative procedure;
            </p>
            <p></p>
            <p>
              - perform other duties stipulated by this Law and other acts
              legislation on administrative procedures.
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Article 30. Procedure for appealing against an administrative decision</b>
            </h3>
            <p></p>
            <p>
              1. The person concerned has the right to appeal the administrative decision to
              administrative (extrajudicial) order.
            </p>
            <p></p>
            <p>
              2. An administrative complaint is sent to a state body, other organization,
              higher in relation to the authorized body that adopted the administrative
              decision, or to a state body, other organization, to the competence of which
              in accordance with legislative acts, consideration of such complaints (hereinafter -
              body considering the complaint).
            </p>
            <p></p>
            <p>
              3. An appeal against an administrative decision in a judicial procedure is carried out after
              appeal against such a decision in an administrative (out-of-court) procedure, if another procedure
              appeal is not provided for by law.
            </p>
            <p></p>
            <p>
              An appeal against an administrative decision in court is carried out in accordance with
              with civil procedural or economic procedural legislation.
            </p>
            <p></p>
            <p>
              4. Filing a complaint in an administrative (out-of-court) procedure does not deprive the interested
              person of the right to appeal to the court.
            </p>`,

      // AGAINST CORRUPTION

      'Противодействие коррупции': 'Anti-corruption',
      'Описание противодействия коррупции': `<p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H11500305"
              target="_blank"
              rel="noreferrer noopener"
            >
              Law of the Republic of Belarus dated July 15, 2015 No. 305-3 "On the fight against corruption"
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H10300204"
              target="_blank"
              rel="noreferrer noopener"
            >
              Law of the Republic of Belarus dated June 14, 2003 No. 204-Z “On public service in
              Republic of Belarus"
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H11400122"
              target="_blank"
              rel="noreferrer noopener"
            >
              Law of the Republic of Belarus dated January 4, 2014 No. 122-Z “On the basics of activities for
              crime prevention"
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H10300174"
              target="_blank"
              rel="noreferrer noopener"
            >
              Law of the Republic of Belarus dated January 4, 2003 No. 174-З “On Declaration by Individuals
              persons of income and property"
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=HK9900275"
              target="_blank"
              rel="noreferrer noopener"
            >
              Criminal Code of the Republic of Belarus dated July 9, 1999 No. 275-Z
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=Hk0300194"
              target="_blank"
              rel="noreferrer noopener"
            >
              Code of the Republic of Belarus on Administrative Offenses dated April 21, 2003 No.
              194-Z
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="/upload/plan_meropriyatiy_po_borbe_s_korruptsiey_2020-2022.docx"
            >
              Plan of measures to combat corruption in JSC "Belaruskabel" for 2020-2022
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/file_kor_01.pdf">
              On the prevention and settlement of conflicts of interest
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/polozhenie_komisii_po_kor.pdf">
              Regulations on the Commission for Combating Corruption of Belaruskabel OJSC
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/polozhenie_o_zakupkah.pdf">
              Regulations on the procurement of goods (works, services) at the expense of own funds
            </a>
          </p>
          <p>
            <br />
          </p>
          <p>
            Note: “If there are signs of corruption offenses or offenses,
            creating conditions for corruption (Articles 25 and 37 of the Law of the Republic of Belarus “On Combating
            corruption”), administrative offenses related to corruption can be
            the offenses provided for in Articles 9.6, 9.13, 9.26, 10.5, 11.16, 11.77,
            23.81, 23.82, 23.83, 23.84 of the Code of the Republic of Belarus on Administrative
            offences."
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=HK9900296"
              target="_blank"
              rel="noreferrer noopener"
            >
              Labor Code of the Republic of Belarus dated July 26, 1999 No. 296-Z
            </a>
          </p>
          <p>
            Note: “The Labor Code of the Republic of Belarus establishes:
            <br />Article 27 - restrictions on joint work in public organizations (their
            separate subdivisions) spouses, close relatives and in-laws;
          </p>
          <p>
            in paragraph 5 of article 47 - the right of the employer to terminate the employment contract with
            public official for failure to sign an undertaking to comply
            anti-corruption restrictions;
          </p>
          <p>
            in paragraph 5-1 of article 47 - the right of the employer to terminate the employment contract with
            by a public official for breach of an obligation to comply
            anti-corruption restrictions, as well as for committing an offense that creates conditions
            for corruption, or corruption offense;
          </p>
          <p>
            in article 255 - restrictions on part-time work for heads of state
            organizations and organizations, in the authorized funds of which 50 or more percent of shares (stakes)
            are state-owned, as well as a ban on heads of organizations
            of any form of ownership to be part of the bodies exercising the functions of supervision and
            control in the relevant organizations.
          </p>
          <p>&nbsp;</p>
          <h2>LOCAL LEGAL ACTS OF THE MINISTRY</h2>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/plan_meropriyatij_korupcii2016_2017.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Action plan for combating corruption in the system of the Ministry of Industry
              Republic of Belarus for 2018-2019
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/prikaz_505.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Order of the Ministry of Industry of the Republic of Belarus dated December 29, 2017 No. 505 "O
              amendments to the order of the Ministry of Industry of the Republic of Belarus dated 26
              January 2012 No. 49"
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/sostav_kom_2014.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Order of the Ministry of Industry of the Republic of Belarus dated March 27, 2014 No. 132 “On
              amendments to the order of the Ministry of Industry of the Republic of Belarus” (introduced
              amendments to order No. 49 dated January 26, 2012, which determined the new composition of the commission
              on combating corruption)
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/nov_pol_kom.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Order of the Ministry of Industry of the Republic of Belarus dated August 21, 2015 No. 407 “On
              making additions and changes to the order of the Ministry of Industry of the Republic
              Belarus dated January 26, 2012 No. 49” (approved a new version of the Regulations on the Commission for
              Anti-Corruption Ministry of Industry of the Republic of Belarus)
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/lokalnue_pravovue_aktu"
              target="_blank"
              rel="noreferrer noopener"
            >
              Local legal acts of the Ministry
            </a>
          </p>
          <p>
            <br />
          </p> 
          <h2>ANTI-CORRUPTION MEASURES</h2>
          <p>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="/upload/plan_meropriyatiy_po_borbe_s_korruptsiey_2020-2022.docx"
            >
              Plan of measures to combat corruption in JSC "Belaruskabel" for 2020-2022
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/tip_pravila.pdf">
              Model Rules for Anti-Corruption Conduct in State Bodies and Others
              organizations of the Republic of Belarus
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/polow_o_komisii_2.pdf">
              Regulations on the Commission for Combating Corruption of Belaruskabel OJSC
            </a>
          </p>`,

      'Наша продукция отмечена': `Our products have been awarded with certificates, grants and diplomas of international and regional exhibitions, 
      fairs and contests of recent years. The plant has also been repeatedly awarded various prizes - for quality, positive image and the best trade mark.`,
      'ОАО «Беларускабель» на постоянной основе участвует':`JSC "Belaruskabel" regularly participates in "The Best Products of the Republic of Belarus", 
      which is held annually since 2002. During the Contest, various types of cable and wire products were declared:`,
      'Согласно Постановлению от 3 апреля 2020 года':`According to the Decree №203 dd. from April 3, 2020 JSC "Belaruskabel" was awarded the Prize of the 
      Government of the Republic of Belarus for achievements in the field of quality (the period of validity is issued for 3 years).`,
      'единственный в Республике Беларусь':`JSC "Belaruskabel" is the only cable plant in the Republic of Belarus that provides 
      services for technical acceptance of products. Technical Acceptance No. 43 of RT-Techpriyemka JSC, 
      a specialised organisation of Rostec State Corporation (formerly Aviatechpriyemka JSC) for conformity assessment of products 
      in the form of quality control and technical acceptance, in accordance with the Regulation dated 22.10.2007 "On the procedure 
      for conducting quality control of materials and semi-finished products used in the production of weapons, military, 
      aviation and special technology", is deployed at Belaruskabel JSC.`,
      'ОАО «Беларускабель» входит в состав организаций':`JSC "Belaruskabel" is a member of the Elektrokabel Association, 
      that have signed the Quality Declaration adopted at the General Meeting on April 16, 2008. 
      The enterprise is also a member of the Association of Cable Industry Enterprises "Belkabel".`,
      '- «Лучшие товары Республики Беларусь» 2024 год НОВИНКА ГОДА - кабель плоский лифтовой гибкий комбинированный марки КПЛГК;':
      `- "The best goods of the Republic of Belarus" 2024 NEW PRODUCT OF THE YEAR - flat flexible combined elevator cable of the KPLGK brand;`,      
      '- «Лучшие товары Республики Беларусь» 2023 год НОВИНКА ГОДА - кабель для сигнализации и блокировки, гибкий СБМВ;':
      `- "The Best Goods of the Republic of Belarus 2023 " – New Product of the Year - Flexible signal blocking cable, SBMV;`,
      '- «Лучшие товары Республики Беларусь» 2022 год НОВИНКА ГОДА - провод барьерный электризуемый БЭ-БЭП.000;':
      `- "The Best Goods of the Republic of Belarus 2022 " - New Product of the Year - electrified barrier wire BE-BEP.000;`,
      '- «Лучшие товары Республики Беларусь» 2021 год - вилка опрессованная 83-16-250-60227 IEC 53;':
      `- "The Best Goods of the Republic of Belarus 2021" - pressed plugs 83-16-250-60227 IEC 53;`,
      '- «Лучшие товары Республики Беларусь» 2020 год НОВИНКА ГОДА - кабель силовой гибкий КГВЭВнг(А);':
      `- "The Best Goods of the Republic of Belarus 2020" - New Product of the Year - flexible power cable - KGVEVng(A);`,
      '- «Лучшие товары Республики Беларусь» 2019 год НОВИНКА ГОДА - кабель плоский лифтовой гибкий;':
      `- "The Best Goods of the Republic of Belarus 2019 " - New Product of the Year - flat lift flexible cable;`,
      '- «Лучшие товары Республики Беларусь» 2018 год НОВИНКА ГОДА - кабель контроля и передачи данных «Арктический КУПД»;':
      `- "The Best Goods of the Republic of Belarus 2018" - New Product of the Year - cable of control and data transmission - "Arctic KUPD";`,
      '- «Лучшие товары Республики Беларусь» 2017 год НОВИНКА ГОДА - кабель для систем управления и сигнализации, не распространяющий горения, с изоляцией и оболочкой из полимерных композиций, не содержащих галогенов КПЭПнг(А)-FRHF;':
      `- "The Best Goods of the Republic of Belarus 2017" - New Product of the Year - flame retardant cable for control and alarm systems, with insulation and sheath made of polymer compositions, halogen-free - KPEPng(A)-FRHF;`,
      '- и ранее.':`- and earlier.`,

      'Заг Наименование': 'Name',
      'Заг Масса': 'Weight',
      'Заг Количество': 'Amount',
      'Номинальное сечение жил': 'Nominal cross-section of conductors, mm² -',
      'Число жил': 'Number of cores',
      'Удалить из корзины': 'Remove from cart',
      'Цена по запросу': 'Price on request',
      'Оформить заказ': 'Checkout',
      'Продолжить покупки': 'Continue Shopping',
      'На данный момент ваша корзина пуста.': 'Your cart is currently empty.',
      'В каталоге вы можете найти интересующие вас':
        'In the catalog you can find the ones you are interested in',
      'Перейти в каталог': 'Go to catalog',
      'allCategories': 'All categories',
      'Политика в отношении обработки файлов cookie': 'Cookie Policy'
    },
  },
  bel: {
    translation: {
      'Политика в отношении обработки персональных данных' : 'Палітыка ў дачыненні да апрацоўкі персанальных дадзеных',
      'Политика видеонаблюдения': 'Палітыка відэаназірання',
      'Весь ассортимент': 'Увесь асартымент',
      'Нам доверяют': 'Нам давяраюць',
      'Шапка Корзина': 'Кошык',
      // HEADER
      'Производим кабельную продукцию c 1959 года': 'Вырабляем кабельную прадукцыю з 1959 года',
      'г. Мозырь, ул. Октябрьская, 14.': 'г.Мазыр, вул. Кастрычніцкая, 14.',
      'номер приёмной': 'Прыёмная',
      'отдел продаж': 'Аддзел продажаў',
      'Мы в социальных сетях': 'Мы ў сацыяльных сетках',
      'Фирменный магазин': 'Фірменны магазін',     
      // 'Оставить заявку': 'Пакінуць заяўку',
      'Войти в аккаунт': 'Увайсці',
      'Меню каталог': 'Каталог',
      'Меню производство': 'Вытворчасць',
      'Менеджмент качества': 'Мэнэджмент якасці',
      'Центральная заводская лаборатория': 'Цэнтральная завадская лабараторыя',
      'Инновационная продукция': 'Інавацыйная прадукцыя',
      'Меню О компании': 'Аб кампаніі',
      'Меню История': 'Гісторыя',
      'Меню Руководство': 'Кіраўніцтва',
      'СМИ о нас': 'СМІ пра нас',
      'Меню Фотогалерея': 'Фотагалерэя',
      'Меню Дилеры': 'Дылеры',
      'Меню Кибербезопасность': 'Кібербяспека',
      'Наши достижения': 'Нашы дасягненні',
      'Меню Контакты': 'Кантакты',
      'Меню Главная': 'Галоўная',
      'Электронный каталог': 'Электронны каталог',
      'Предложения по импортозамещению': 'Прапановы да супрацоўніцтва',
      'Калькулятор кабеля, база для AUTOCAD': 'Калькулятар кабеля, база для AUTOCAD',
      'Меню Новости': 'Навіны',
      'Меню Общее': 'Агульная',
      'Предложения по импортозамещению': 'Прапановы па імпартазамяшчэнні',
      'Меню Вакансии': 'Вакансіі',
      'График личного приема граждан меню': 'Графік асабістага прыёму грамадзян',
      'График личного приема граждан':
        'Графік асабістага прыёму грамадзян, індывідуальных прадпрымальнікаў і прадстаўнікоў юрыдычных асоб кіраўніцтвам ААТ Беларускабель',
      'Противодействие коррупции': 'Процідзеянне карупцыі',
      'Административные процедуры': 'Адміністрацыйныя працэдуры',
      'Меню Акционерам': 'Акцыянерам',

      // SLIDER

      'Кнопка Перейти': 'Перайсці',

      'КАБЕЛИ СИЛОВЫЕ': 'КАБЕЛІ СІЛАВЫЯ',
      'КАБЕЛИ СИЛОВЫЕ ОП':
        'Для перадачы электраэнергіі токамі прамысловых частот і для стацыянарнай пракладкі.',

      'Лифтовые кабели и провода': 'ЛИФТОВЫЕ КАБЕЛИ И ПРОВОДА',
      'Лифтовые кабели и провода ОП': 'Для эксплуатации на пассажирских <br /> и грузовых лифтах общего назначения.',

      'Кабели контрольные': 'КАБЕЛІ КАНТРОЛЬНЫЯ',
      'Кабели контрольные ОП':
        'Для нерухомага далучэння да электрычных прыбораў, апаратам, зборкам заціскаў электрычных размеркавальных прылад.',

      'КАБЕЛИ УПРАВЛЕНИЯ': 'КАБЕЛІ КІРАВАННЯ <br /> І ПЕРАДАЧЫ ДАНЫХ',
      'КАБЕЛИ УПРАВЛЕНИЯ ОП':
        'Кабелі прызначаны для перадачы сігналаў да апаратуры кантролю, выкарыстоўваюцца для дыстанцыйнага кіравання выканаўчымі механізмамі кіравання і для міжпрыборнага злучэння пры напрузе да 1 КВ',

      'Продукция для электрической установки железнодорожной сигнализации, централизации и блокировки':
        'Прадукцыя для электрычнай усталёўкі чыгуначнай сігналізацыі, цэнтралізацыі і блакаванні',

      'КАБЕЛИ И ПРОВОДА СВЯЗИ': 'КАБЕЛІ І ПРАВОДА СУВЯЗІ',
      'КАБЕЛИ И ПРОВОДА СВЯЗИ ОП':
        'Выкарыстоўваецца для пракладкі ліній тэлефоннай сувязі <br />і размеркавальных сетак',

      'КАБЕЛИ И ПРОВОДА': 'КАБЕЛІ І ПРАВОДЫ ДЛЯ <br /> СІГНАЛІЗАЦЫІ І ВІДЭАНАБЛЮДЭННЯ',
      'КАБЕЛИ И ПРОВОДА ОП':
        'Для мантажу сістэм сігналізацыі і эксплуатацыі ў умовах стацыянарнай і нестацыянарнай пракладкі',

      'КАБЕЛИ МОНТАЖНЫЕ': 'Кабель і правады <br /> МАНТАЖНЫЯ',
      'КАБЕЛИ МОНТАЖНЫЕ ОП':
        'Прызначаны для перадачы электрычнай энергіі ў межах адной электрычнай усталёўкі, электрычнага прыбора або апарата.',
      'Кабель для железной дороги': 'Кабель для чыгункі',
      'Кабели и провода для сигнализации и видеонаблюдения':
        'Кабелі і правады для сігналізацыі і відэаназірання',
      'КАБЕЛИ И ПРОВОДА МОНТАЖНЫЕ': 'КАБЕЛІ І ПРАВОДЫ МАНТАЖНЫЯ',

      // Advantages
      'Широкая производимая номенклатура кабельных изделий': 'Широкая производимая номенклатура кабельных изделий',
      'Наличие складской программы': 'Наличие складской программы',
      'Короткие сроки изготовления': 'Короткие сроки изготовления',
      'Высококвалифицированный персонал': 'Высококвалифицированный персонал',
      'Возможность доставки и самовывоза': 'Возможность доставки и самовывоза',
      'Выгодная стоимость': 'Выгодная стоимость',
      'Качество подтверждено сертификатами': 'Качество подтверждено сертификатами',
      'Сертифицированная система менеджмента качества': 'Сертифицированная система менеджмента качества',
      'Проведение испытаний 100% продукции': 'Проведение испытаний 100% продукции',
      'Своевременная модернизация и внедрение инноваций на производстве': 'Своевременная модернизация и внедрение инноваций на производстве',
      'История традиций по производству кабельной продукции с 1959 г': 'История традиций по производству кабельной продукции с 1959 г',

      'Розничная торговля от 1 метра, напрямую от производителя':
      'Рознічны гандаль ад 1 метра, напрамую ад вытворцы',
      'Интернет-магазин розничной торговли': 'Інтэрнэт-крама рознічнага гандлю',
      'Оптовые цены для юр лиц': 'Аптовыя цэны для юр асоб',
      'Запросить коммерческое предложение': 'Запытаць камерцыйную прапанову',
      'История становления ОАО «Беларускабель»': 'Гісторыя станаўлення ААТ "Беларускабель',
      'Познакомиться с организацией': 'Пазнаёміцца з арганізацыяй',
      'Подбор продукции по характеристикам': 'Падбор прадукцыі па характарыстыках',
      'Калькулятор кабеля': 'Калькулятар кабеля',
      'Свободные остатки готовой продукции на складе':'Вольныя рэшткі гатовай прадукцыі на складзе',
      'Посмотреть остатки':'Паглядзець рэшткі',

      // Search

      'Поиск по марке кабеля': 'Пошук па марцы кабеля',
      'Кнопка Искать': 'Шукаць',

      // PRODUCTION
      'Заг Продукция': 'Прадукцыя',

      // BANNER

      'ВЫСОКОЕ КАЧЕСТВО ГАРАНТИРОВАНО': 'Высокая якасць гарантавана',
      'Вся продукция сертифицирована и производится в соответствии с ТУ и ГОСТ':
        'Уся прадукцыя сертыфікавана і вырабляецца ў адпаведнасці з ТУЮ і ДАСТ',

      // SECOND BANNER

      'Интернет магазин «Беларускабель»': 'Інтэрнэт крама «Беларускабель»',
      'Покупайте кабель онлайн':
        'Купляйце кабель анлайн (ад 1 метра) <br /> у фірмовай краме напрамую ад вытворцы <br />',
      'Рассрочка от 3 месяцев': 'Растэрміноўка ад 3 месяцаў',
      'Оплата онлайн': 'Аплата онлайн',
      'Широкий ассортимент': 'Шырокі асартымент',
      'Выгодные цены': 'Выгадныя кошты',
      'Перейти в Интернет магазин': 'Перайсці ў Інтэрнэт краму',

      // INDUSTRIES

      'Отрасли применения продукции': 'Галіны прымянення прадукцыі',

      // COUNTRIES

      'Страна Россия': 'Расія',
      'Страна Китай': 'Кітай',
      'Страна Украина': 'Украіна',
      'Страна Великобритания': 'Вялікабрытанія',
      'Страна Молдова': 'Малдова',
      'Страна Казахстан': 'Казахстан',
      'Страна Киргизия': 'Кіргізія',
      'Страна Азербайджан': 'Азербайджан',
      'Страна Туркменистан': 'Туркменістан',
      'Страна Литва': 'Літва',
      'Страна Латвия': 'Латвія',
      'Страна Эстония': 'Эстонія',
      'Страна Чехия': 'Чэхія',
      'Страна Польша': 'Польшча',
      'Страна Грузия': 'Грузія',
      'Страна Сербия': 'Сербія',
      'Страна Египет': 'Егіпет',
      'Страна Кипр': 'Кіпр',
      'Страна Германия':'Германія',
      'Страна Панама':'Панама',
      'Страна Афганистан':'Афганістан',
      'Страна Болгария':'Балгарыя',
      'Страна Индия':'Індыя',
      'Страна Иран':'Іран',
      'Страна Армения':'Арменія',
      'Страна Хорватия':'Харватыя',
      'География поставок': 'Геаграфія паставак',

      // ACHIEVEMENTS

      'Достижения и Сертификаты': 'Дасягненні і Сертыфікаты',
      'Заг Новости': 'Навіны',
      'Все новости': 'Усе навіны',

      // THIRD BANNER

      'Заг Преимущества': 'Перавагі',
      'количество кабеля': '143 799 <span>км.</span>',
      'кабеля произведено в 2021 году': 'кабеля выраблена ў 2021 годзе',
      'переработано меди': '614 <span>тон</span>',
      'меди переработано за 2021 год': 'медзі перапрацавана за 2021 год',
      'колво человек': '420 <span>чалавек</span>',
      'среднесписочная численность': 'сярэднеспісачная колькасць',
      'колво инновационной': '88 <span>адз.</span>',
      'инновационной продукции': 'інавацыйнай прадукцыі',
      'готовой продукции': '40 343 <span>км.</span>',
      'готовой продукции на складе': 'гатовай прадукцыі на складзе',
      'колво оборудования': 'Больш за 20 <span>адз.</span>',
      'модернизированного оборудования': 'мадэрнізаванага абсталявання',

      // PARTNERS

      'Интернет-портал Президента Республики Беларусь':
        'Інтэрнет-партал Прэзідэнта Рэспублікі Беларусь',
      'Национальный правовой интернет-портал Республики Беларусь':
        'Нацыянальны прававы інтэрнэт-партал Рэспублікі Беларусь',
      'Мозырский районный исполнительный комитет': 'Мазырскі раённы выканаўчы камітэт',
      'Министерство промышленности Республики Беларусь':
        'Міністэрства прамысловасці Рэспублікі Беларусь',

      // FOOTER

      'Описание снизу': `Больш за 60 гадоў ААТ "Беларускабель" - адно з вядучых кабельных прадпрыемстваў у
                Рэспубліцы Беларусь, якая вырабляе і пастаўляе кабельную прадукцыю для
                галін і сфер спажывання - такіх як радыёэлектроніка, прыборабудаванне, сувязь,
                авіяцыйная тэхніка, будаўніцтва, тэлекамунікацыя і электратэхніка. Мы заўсёды
                рады новым праектам і партнёрам! Вырабляем кабель і провад па Дасце, прыманы
                заказы на распрацоўку і вытворчасць па Вашым ТЗ і ТУ, заўсёды гатовы прадаставіць
                кансультацыю па любых пытаннях.`,

      // BREADCRUMBS
      'Меню Компания': 'Кампанія',

      // HISTORY PAGE
      'Предприятие ОАО': `<span>Прадпрыемства ААТ "Беларускабель"</span> (раней "Мазыркабель") арганізавана ў
                    найстарэйшым горадзе беларускага Палесся ў 1959 г. На ўзвышшы каля самай ракі
                    Прыпяць размясціліся корпусы завода. З моманту ўтварэння прадпрыемства лічыцца
                    адным з найлепшых у кабельнай галіне СНД.`,
      'За годы деятельности ОАО «Беларускабель»': `<span>За гады дзейнасці ААТ «Беларускабель»</span> значна выраслі
                вытворчыя магутнасці, сфармаваўся дасведчаны калектыў, які вырашае сур'ёзныя
                інжынерныя задачы па пастаноўцы на вытворчасць новых кабельна-правадніковых вырабаў
                і засваенню прагрэсіўнай тэхналогіі. На прадпрыемстве быў усталяваны электронны
                паскаральнік, у той час адна з самых прагрэсіўных тэхналогій, для апрамянення
                ізаляцыі правадоў, што істотна палепшыла фізіка-механічныя характарыстыкі
                правадоў і дазволіла ставіць на вытворчасць правады, якія выкарыстоўваюцца для авіяцыйнай
                прамысловасці`,
      'В настоящее время': `<span>У цяперашні час</span> прадпрыемства вырабляе мантажныя правады,
                тэрмарадыяцыйнастойкія правады, малагабарытныя і цеплаўстойлівыя правады, сілавыя.
                кабелі і кантрольныя кабелі, кабелі сувязі, радыёчастотныя кабелі, а таксама кабелі і
                правады рознага спецыяльнага прызначэння з жылой з меднай і алюмініевай
                дроту. Сфера прымянення выпускаемых вырабаў ахоплівае такія галіны народнага.
                гаспадаркі, як радыёэлектроніка, авіяцыйная тэхніка, прыборабудаванне,
                будаўніцтва, сувязь, тэлекамунікацыя і электратэхніка.`,

      // ADMINISTRATIVE PROCEDURES

      'Делопроизводитель Дубинина Людмила': `Справаводчык Дубініна Людміла Сяргееўна, кабінет "Канцылярыя" на 1 паверсе, тэлефон
             +375 236 37 25 08; <br /> Спецыяліст па кадрах Шчарбін Святлана Іванаўна, кабінет «Аддзел
             кадраў» на 1 паверсе, тэлефон +375 236 37 25 66; <br /> Сакратар прыёмнай Марцiновіч Багдана Русланаўна, "Прыёмная" на 3 паверсе, тэлефон +375 236 24 77 29; <br /> Галоўны
             бухгалтар Белька Ганна Аляксандраўна, кабінет «Галоўнага бухгалтара» прыёмная на 3 паверсе,
             тэлефон +375 236 24 65 23; <br /> Старшыня прафкама Сідарэнка Святлана Іванаўна,
             тэлефон +375 236 25 40 64`,

      'ПРИЕМ ЗАЯВЛЕНИЙ ОСУЩЕСТВЛЯЕТСЯ ПО РАБОЧИМ ДНЯМ': `ПРЫЁМ ЗАЯЎ АЖЫЦЦАЎЛЯЕЦЦА ПА РАБОЧЫХ ДНЯХ <br /> З 08:00 ДА 12:45 І З 13:30 ДА
               17:00, <br /> ПЯТНІЦА З 08:00 ДА 12:45 І З 13:30 ДА 15:45.`,
      'Основные принципы': `Асноўныя прынцыпы ажыццяўлення адміністрацыйных працэдур змяшчаюцца ў 
             <a href="http://www.pravo.by/document/?guid=3871&amp;p0=h10800433" target="_blank" rel="noreferrer noopener">
               Законе Рэспублікі Беларусь у Законе Рэспублікі Беларусь ад 28.10.2008 года нумар 433-
             </a>
             <a href="http://www.pravo.by/document/?guid=3871&amp;p0=h10800433" target="_blank" rel="noreferrer noopener">
               3 «Аб асновах адміністрацыйных працэдур»
             </a>. `,
      'Административные процедуры, осуществляемые в отношении граждан, юридических лиц и индивидуальных предпринимателей, определяются следующими перечнями': `Адміністрацыйныя працэдуры, якія ажыццяўляюцца ў адносінах да грамадзян, юрыдычных асоб і
             індывідуальных прадпрымальнікаў, вызначаюцца наступнымі пералікамі`,
      'перечень административных процедур': `
            пералік адміністрацыйных працэдур, якія ажыццяўляюцца дзяржаўнымі органамі і іншымі
             арганізацыямі па заявах грамадзян, зацверджаны
             <a href="http://www.pravo.by/document/?guid=3871&amp;p0=P31000200" target="_blank" rel="noreferrer noopener">
               
               указам
             </a>`,
      'Президента Республики Беларусь': `
            <a href="http://www.pravo.by/document/?guid=3871&amp;p0=P31000200" target="_blank" rel="noreferrer noopener">
               Прэзідэнта Рэспублікі Беларусь ад 26.04.2010 № 200
             </a>
             ;`,
      'единый перечень административных процедур': `
            адзіны пералік адміністрацыйных працэдур, якія ажыццяўляюцца дзяржаўнымі органамі і
             іншымі арганізацыямі ў адносінах да юрыдычных асоб і індывідуальных прадпрымальнікаў,
             зацверджаны
             <a href="http://pravo.by/document/?guid=3871&amp;p0=C21200156" target="_blank" rel="noreferrer noopener">
               
               пастановай Рады
             </a>`,
      'описание административных процедур': `<p>
              <a href="http://pravo.by/document/?guid=3871&amp;p0=C21200156" target="_blank" rel="noreferrer noopener">
                Міністраў Рэспублікі Беларусь ад 17.02.2012 № 156
              </a>
              .
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <p>
              Пералік адміністрацыйных працэдур, якія ажыццяўляюцца ААТ «Беларускабель» прадстаўлены ў {''}
              <a target="_blank" rel="noreferrer noopener" href="/upload/per-prots-pril-1.pdf">
                дадатку № 1
              </a>
              .
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Парадак ажыццяўлення працэдур</b>
            </h3>
            <p></p>
            <p>Закон Рэспублікі Беларусь ад 28.10.2008 г. № 433-З</p>
            <p></p>
            <p>«Аб асновах адміністрацыйных працэдур» (выпіска)</p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <p>Артыкул 9. Кампетэнцыя ўпаўнаважаных органаў</p>
            <p></p>
            <p>Упаўнаважаныя органы пры ажыццяўленні адміністрацыйных працэдур:</p>
            <p></p>
            <p>
              - забяспечваюць добразычлівыя, ветлівыя і ўважлівыя адносіны работнікаў
              упаўнаважанага органа да зацікаўленыхасоб;
            </p>
            <p></p>
            <p>
              - забяспечваюць своечасовае і належнае выкананне функцый часова адсутных
              работнікаў упаўнаважаных органаў, якія ажыццяўляюць прыём зацікаўленых асоб, іншымі
              работнікамі гэтыхорганаў;
            </p>
            <p></p>
            <p>
              - тлумачаць зацікаўленым асобам абавязак упаўнаважаных органаў самастойна
              запытваць дакументы і (або) звесткі, неабходныя для ажыццяўлення адміністрацыйных
              працэдур, за выключэннем тых, якія ўключаны ў пералікі дакументаў і (або) звестак,
              прадстаўляюцца зацікаўленымі асобамі, а таксама правы і абавязкі
              зацікаўленыхасоб;
            </p>
            <p></p>
            <p>
              - бясплатна падаюць зацікаўленым асобам прадугледжаныя заканадаўствам аб
              адміністрацыйных працэдурах формы (бланкі) дакументаў, неабходных для звароту за
              ажыццяўленнем адміністрацыйных працэдур, і тлумачаць парадак іх запаўнення і
              прадстаўлення;
            </p>
            <p></p>
            <p>
              - запытваюць ад іншых дзяржаўных органаў, іншых арганізацый дакументы і (або)
              звесткі, неабходныя для ажыццяўлення адміністрацыйныхпрацэдур;
            </p>
            <p></p>
            <p>
              - даюць зацікаўленым асобам магчымасць знаёміцца ​​з матэрыяламі,
              звязанымі з разглядам сваіх заяў, у тым ліку з дакументамі і (або)
              звесткамі, атрыманымі гэтымі органамі ад іншых дзяржаўных органаў, іншых
              арганізацый і неабходнымі для ажыццяўлення адміністрацыйных працэдур, рабіць з іх
              выпіскі, калі іншае не прадугледжана заканадаўствам аб дзяржаўных сакрэтах,
              камерцыйнай ці іншай ахоўнай заканамернай;
            </p>
            <p></p>
            <p>- паведамляюць зацікаўленым асобам аб прынятых адміністрацыйныхрашэннях;</p>
            <p></p>
            <p>
              - тлумачаць зацікаўленым асобам парадак і тэрміны абскарджання прынятых
              адміністрацыйныхрашэнняў;
            </p>
            <p></p>
            <p>
              - ажыццяўляюць іншыя паўнамоцтвы, прадугледжаныя гэтым Законам і іншымі актамі.
              заканадаўства аб адміністрацыйных працэдурах.
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            аб заканадаўствам аб дзяржаўных сакрэтах,
              камерцыйнай ці іншай ахоўнай заканамернай;
            </p>
            <p></p>
            <p>- атрымліваць адміністрацыйныя рашэнні або выпіскі зіх;</p>
            <p></p>
            <p>
              - адклікаць сваю заяву ў любы час да заканчэння ажыццяўлення адміністрацыйнай
              працэдуры;
            </p>
            <p></p>
            <p>- абскардзіць прынятыя адміністрацыйныя рашэнні;</p>
            <p></p>
            <p>
              - ажыццяўляць іншыя правы, прадугледжаныя гэтым Законам і іншымі актамі
              заканадаўства аб адміністрацыйных працэдурах.
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Артыкул 10. Правы зацікаўленых асоб</b>
            </h3>
            <p></p>
            <p>Зацікаўленыя асобы маюць права:</p>
            <p></p>
            <p>- звяртацца з заявамі ва ўпаўнаважаныя органы;</p>
            <p></p>
            <p>
              - бясплатна атрымліваць ад упаўнаважаных органаў прадугледжаныя заканадаўствам аб
              адміністрацыйных працэдурах формы (бланкі) дакументаў, неабходных для звароту за
              ажыццяўленнем адміністрацыйныхпрацэдур;
            </p>
            <p></p>
            <p>- атрымліваць ад упаўнаважаных органаў растлумачэнне сваіх правоў іабавязацельстваў;</p>
            <p></p>
            <p>
              - прымаць удзел у адміністрацыйных працэдурах асабіста і (або) праз сваіх
              прадстаўнікоў, калі іншае не прадугледжана заканадаўчымі актамі;
            </p>
            <p></p>
            <p>
              - знаёміцца ​​з матэрыяламі, звязанымі з разглядам сваіх заяў, рабіць з іх
              выпіскі, калі іншае не прадугледжана заканадаўствам аб дзяржаўных сакрэтах,
              камерцыйнай ці іншай ахоўнай заканамернай;
            </p>
            <p></p>
            <p>- атрымліваць адміністрацыйныя рашэнні або выпіскі зіх;</p>
            <p></p>
            <p>
              - адклікаць сваю заяву ў любы час да заканчэння ажыццяўлення адміністрацыйнай
              працэдуры;
            </p>
            <p></p>
            <p>- абскардзіць прынятыя адміністрацыйныя рашэнні;</p>
            <p></p>
            <p>
              - ажыццяўляць іншыя правы, прадугледжаныя гэтым Законам і іншымі актамі
              заканадаўства аб адміністрацыйных працэдурах.
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Артыкул 11. Абавязкі зацікаўленых асоб</b>
            </h3>
            <p></p>
            <p>Зацікаўленыя асобы абавязаны:</p>
            <p></p>
            <p>
              - ветліва ставіцца да работнікаў упаўнаважаных органаў, не дапускаць нецэнзурных
              або абразлівых слоў або выразаў у дачыненні да такіх работнікаў;
            </p>
            <p></p>
            <p>
              - прадстаўляць ва ўпаўнаважаныя органы дакументы і (або) звесткі, уключаныя ў
              пералікі дакументаў і (або) звестак, якія прадстаўляюцца зацікаўленымі асобамі, а таксама
              дакументы, указаныя ў абзацах другім - сёмым пункта 2 артыкула 15 гэтага Закона,
              у выпадку запатрабавання такіх дакументаў;
            </p>
            <p></p>
            <p>- уносіць плату, якая спаганяецца пры ажыццяўленні адміністрацыйныхпрацэдур;</p>
            <p></p>
            <p>
              - своечасова інфармаваць упаўнаважаныя органы аб змяненні месца жыхарства
              (месцы знаходжання), месцы знаходжання ў перыяд ажыццяўлення адміністрацыйнайпрацэдуры;
            </p>
            <p></p>
            <p>
              - выконваць іншыя абавязкі, прадугледжаныя гэтым Законам і іншымі актамі
              заканадаўства аб адміністрацыйных працэдурах.
            </p>
            <p></p>
            <p>&nbsp;</p>
            <p></p>
            <h3 className={s.schedules}>
              <b>Артыкул 30. Парадак абскарджання адміністрацыйнага рашэння</b>
            </h3>
            <p></p>
            <p>
              1. Зацікаўленая асоба валодае правам на абскарджанне адміністрацыйнага рашэння ў
              адміністрацыйным (пазасудовым)парадку.
            </p>
            <p></p>
            <p>
              2.Адміністрацыйная скарга накіроўваецца ў дзяржаўны орган, іншую арганізацыю,
              вышэйстаячыя ў адносінах да ўпаўнаважанага органа, які прыняў адміністрацыйнае
              рашэнне, альбо ў дзяржаўны орган, іншую арганізацыю, да кампетэнцыі якіх у
              адпаведнасці з заканадаўчымі актамі адносіцца разгляд такіх скарг (далей -
              орган, які разглядае скаргу).
            </p>
            <p></p>
            <p>
              3. Абскарджанне адміністрацыйнага рашэння ў судовым парадку ажыццяўляецца пасля
              абскарджання такога рашэння ў адміністрацыйным (пазасудовым) парадку, калі іншы парадак
              абскарджання не прадугледжаны заканадаўчымі актамі.
            </p>
            <p></p>
            <p>
              Абскарджанне адміністрацыйнага рашэння ў судовым парадку ажыццяўляецца ў адпаведнасці
              з грамадзянскім працэсуальным ці гаспадарчым працэсуальным заканадаўствам.
            </p>
            <p></p>
            <p>
              4. Падача скаргі ў адміністрацыйным (пазасудовым) парадку не пазбаўляе зацікаўленае
              асоба права на абыходжанне са скаргай усуд.
            </p>`,

      // AGAINST CORRUPTION

      'Противодействие коррупции': 'Процідзеянне карупцыі',
      'Описание противодействия коррупции': `<p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H11500305"
              target="_blank"
              rel="noreferrer noopener"
            >
              Закон Рэспублікі Беларусь ад 15 ліпеня 2015 г. № 305-3 "Аб барацьбе з карупцыяй"
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H10300204"
              target="_blank"
              rel="noreferrer noopener"
            >
              Закон Рэспублікі Беларусь ад 14 чэрвеня 2003 г. № 204-З “Аб дзяржаўнай службе ў
              Рэспубліцы Беларусь»
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H11400122"
              target="_blank"
              rel="noreferrer noopener"
            >
              Закон Рэспублікі Беларусь ад 04 студзеня 2014 г. № 122-З “Аб асновах дзейнасці па
              прафілактыцы правапарушэнняў»
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=H10300174"
              target="_blank"
              rel="noreferrer noopener"
            >
              Закон Рэспублікі Беларусь ад 4 студзеня 2003 г. № 174-З “Аб дэклараванні фізічнымі
              асобамі даходаў і маёмасці»
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=HK9900275"
              target="_blank"
              rel="noreferrer noopener"
            >
              Крымінальны кодэкс Рэспублікі Беларусь ад 9 ліпеня 1999 г. № 275-З
            </a>
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=Hk0300194"
              target="_blank"
              rel="noreferrer noopener"
            >
              Кодэкс Рэспублікі Беларусь аб адміністрацыйных правапарушэннях ад 21 красавіка 2003 г. №
              194-З
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="/upload/plan_meropriyatiy_po_borbe_s_korruptsiey_2020-2022.docx"
            >
              План мерапрыемстваў па супрацьдзеянні карупцыі ў ААТ "Беларускабель" на 2020-2022 гады
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/file_kor_01.pdf">
              Аб прадухіленні і ўрэгуляванні канфліктаў інтарэсаў
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/polozhenie_komisii_po_kor.pdf">
              Палажэнне аб камісіі па супрацьдзеянні карупцыі ААТ Беларускабель
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/polozhenie_o_zakupkah.pdf">
              Палажэнне аб закупках тавараў (работ, паслуг) за кошт уласных сродкаў
            </a>
          </p>
          <p>
            <br />
          </p>
          <p>
            Заўвага: «Пры наяўнасці прыкмет карупцыйных правапарушэнняў ці правапарушэнняў,
            якія ствараюць умовы для карупцыі (артыкулы 25 і 37 Закона Рэспублікі Беларусь «Аб барацьбе з
            карупцыяй»), да адміністрацыйных правапарушэнняў, звязаных з карупцыяй, могуць быць
            аднесены правапарушэнні, прадугледжаныя артыкуламі 9.6, 9.13, 9.26, 10.5, 11.16, 11.77,
            23.81, 23.82, 23.83, 23.84 Кодэкса Рэспублікі Беларусь аб адміністрацыйных
            правапарушэннях».
          </p>
          <p>
            <a
              href="http://pravo.by/document/?guid=3961&amp;p0=HK9900296"
              target="_blank"
              rel="noreferrer noopener"
            >
              Працоўны кодэкс Рэспублікі Беларусь ад 26 ліпеня 1999 г. № 296-З
            </a>
          </p>
          <p>
            Заўвага: «Працоўным кодэксам Рэспублікі Беларусь устаноўлены:
            <br />у артыкуле 27 - абмежаванні сумеснай працы ў дзяржаўных арганізацыях (іх
            адасобленых падраздзяленнях) мужа і жонкі, блізкіх сваякоў і ўласцівых;
          </p>
          <p>
            у пункце 5 артыкула 47 – права наймальніка на спыненне працоўнай дамовы з
            дзяржаўнай службовай асобай за непадпісанне абавязацельства па выкананні
            антыкарупцыйных абмежаванняў;
          </p>
          <p>
            у пункце 5-1 артыкула 47 - права наймальніка на спыненне працоўнага дагавора з
            дзяржаўнай службовай асобай за парушэнне абавязацельства па выкананні
            антыкарупцыйных абмежаванняў, а таксама за здзяйсненне правапарушэння, якое стварае ўмовы
            для карупцыі, ці карупцыйнага правапарушэння;
          </p>
          <p>
            у артыкуле 255 – абмежаванні працы па сумяшчальніцтве для кіраўнікоў дзяржаўных
            арганізацый і арганізацый, у статутных фондах якіх 50 і больш працэнтаў акцый (долей)
            знаходзяцца ў дзяржаўнай уласнасці, а таксама забарона для кіраўнікоў арганізацый
            любых форм уласнасці ўваходзіць у склад органаў, якія ажыццяўляюць функцыі нагляду і
            кантролю ў адпаведных арганізацыях».
          </p>
          <p>&nbsp;</p>
          <h2>ЛАКАЛЬНЫЯ ПРАВАВЫЯ АКТЫ МІНІСТЭРСТВА </h2>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/plan_meropriyatij_korupcii2016_2017.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              План мерапрыемства па супрацьдзеянні карупцыі ў сістэме Міністэрства прамысловасці
              Рэспублікі Беларусь на 2018-2019 гады
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/prikaz_505.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Загад Міністэрства прамысловасці Рэспублікі Беларусь ад 29 снежня 2017 г. №505 "Аб
              унясенні змены ў загад Міністэрства прамысловасці Рэспублікі Беларусь ад 26
              студзеня 2012 г. №49"
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/sostav_kom_2014.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Загад Міністэрства прамысловасці Рэспублікі Беларусь ад 27 сакавіка 2014 г. № 132 “Аб
              унясенні змяненняў у загад Міністэрства прамысловасці Рэспублікі Беларусь» (унесены
              змены ў загад ад 26 студзеня 2012 г. № 49, якімі вызначаны новы склад камісіі
              па супрацьдзеянні карупцыі)
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/_files/nov_pol_kom.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Загад Міністэрства прамысловасці Рэспублікі Беларусь ад 21 жніўня 2015 г. № 407 «Аб
              унясенні дапаўненняў і змяненняў у загад Міністэрства прамысловасці Рэспублікі
              Беларусь ад 26 студзеня 2012 г. № 49» (зацверджана новая рэдакцыя Палажэння аб камісіі па
              супрацьдзеянні карупцыі Міністэрства прамысловасці Рэспублікі Беларусь)
            </a>
          </p>
          <p>
            <a
              href="http://www.minprom.gov.by/lokalnue_pravovue_aktu"
              target="_blank"
              rel="noreferrer noopener"
            >
              Лакальныя прававыя акты Міністэрства
            </a>
          </p>
          <p>
            <br />
          </p>
          <h2>АНТЫКАРУПЦЫЙНЫЯ МЕРАПРЫЕМСТВА </h2>
          <p>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="/upload/plan_meropriyatiy_po_borbe_s_korruptsiey_2020-2022.docx"
            >
              План мерапрыемстваў па супрацьдзеянні карупцыі ў ААТ "Беларускабель" на 2020-2022 гады
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/tip_pravila.pdf">
              Тыпавыя правілы антыкарупцыйных паводзін у дзяржаўных органах і іншых
              арганізацыях Рэспублікі Беларусь
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer noopener" href="/upload/polow_o_komisii_2.pdf">
              Палажэнне аб камісіі па супрацьдзеянні карупцыі ААТ Беларускабель
            </a>
          </p>`,

      'Наша продукция отмечена': `Наша прадукцыя адзначана сведчаннямі, граматамі і дыпломамі міжнародных і рэгіянальных 
                выставак, кірмашоў і конкурсаў апошніх гадоў, таксама заводу былі неаднаразова прысуджаны розныя прызы - за якасць, 
                станоўчы імідж, за лепшую гандлёвую марку.`,
      'ОАО «Беларускабель» на постоянной основе участвует':`ААТ «Беларускабель» на пастаяннай аснове ўдзельнічае ў конкурсе «Лепшыя тавары Рэспублікі Беларусь», 
                які праводзіцца штогод, пачынаючы з 2002 года, па сумеснай ініцыятыве Дзяржстандарта, абласных выканаўчых камітэтаў і Мінгарвыканкама. У працэсе правядзення 
                конкурсу былі заяўлены розныя віды кабельна-правадніковай прадукцыі ААТ «Беларускабель»:`,
        'Согласно Постановлению от 3 апреля 2020 года':`Згодна з Пастановай ад 3 красавіка 2020 года нумар 203 ААТ «Беларускабель» 
                прысуджана Прэмія Урада Рэспублікі Беларусь за дасягненні ў галіне якасці за 2019 год (на 3 гады).`,
        'единственный в Республике Беларусь':`ААТ «Беларускабель» - адзіны ў Рэспубліцы Беларусь кабельны завод, які прадастаўляе паслугі па тэхнічнай 
                прыёмцы прадукцыі. На ААТ «Беларускабель» дыслакуецца Тэхнічная прыёмка нумар 43 АТ «РТ-Тэхпрыёмка», спецыялізаваная арганізацыя Дзяржкарпарацыі 
                «Растэх» (раней АТ «Авіятэхпрыёмка») па ацэнцы адпаведнасці прадукцыі ў форме кантролю якасці і тэхнічнай прыёмцы, згодна з Палажэннем ад 22.10.2000 
                «Аб парадку правядзення кантролю якасці матэрыялаў і паўфабрыкатаў, якія выкарыстоўваюцца ў вытворчасці ўзбраення, ваеннай, авіяцыйнай і спецыяльнай тэхнікі».`,
        'ОАО «Беларускабель» входит в состав организаций':`ААТ «Беларускабель» уваходзіць у склад арганізацый Асацыяцыі «Электракабель», якія падпісалі 
                Дэкларацыю якасці, прынятую на агульным сходзе 16 красавіка 2008 года. Таксама арганізацыя з'яўляецца членам Асацыяцыі прадпрыемстваў кабельнай 
                прамысловасці «Белкабель».`,
        '- «Лучшие товары Республики Беларусь» 2024 год НОВИНКА ГОДА - кабель плоский лифтовой гибкий комбинированный марки КПЛГК;':`- «Лепшыя тавары Рэспублікі Беларусь» 2024 год НАВІНА ГОДА - кабель плоскі ліфтавы гнуткі камбінаваны маркі КПЛГК;`,                
        '- «Лучшие товары Республики Беларусь» 2023 год НОВИНКА ГОДА - кабель для сигнализации и блокировки, гибкий СБМВ;':`«Лепшыя тавары Рэспублікі Беларусь» 2023 год НАВІНА ГОДА - кабель для сігналізацыі і блакіроўкі, гнуткі СБМВ;`,
        '- «Лучшие товары Республики Беларусь» 2022 год НОВИНКА ГОДА - провод барьерный электризуемый БЭ-БЭП.000;':`- «Лепшыя тавары Рэспублікі Беларусь» 2022 год НАВІНА ГОДА - провад бар'ерны электрызаваны БЭ-БЭЗ.000;`,
        '- «Лучшие товары Республики Беларусь» 2021 год - вилка опрессованная 83-16-250-60227 IEC 53;':`- «Лепшыя тавары Рэспублікі Беларусь» 2021 год - відэлец апрасаваны 83-16-250-60227 IEC 53;`,
        '- «Лучшие товары Республики Беларусь» 2020 год НОВИНКА ГОДА - кабель силовой гибкий КГВЭВнг(А);':`- «Лепшыя тавары Рэспублікі Беларусь» 2020 год НАВІНА ГОДА - кабель сілавы гнуткі КГВЭВН(А);`,
        '- «Лучшие товары Республики Беларусь» 2019 год НОВИНКА ГОДА - кабель плоский лифтовой гибкий;':`- «Лепшыя тавары Рэспублікі Беларусь» 2019 год НАВІНКА ГОДА - кабель плоскі ліфтавы гнуткі;`,
        '- «Лучшие товары Республики Беларусь» 2018 год НОВИНКА ГОДА - кабель контроля и передачи данных «Арктический КУПД»;':`- «Лепшыя тавары Рэспублікі Беларусь» 2018 год НАВІНА ГОДА - кабель кантролю і перадачы даных «Арктычны КУПД»;`,
        '- «Лучшие товары Республики Беларусь» 2017 год НОВИНКА ГОДА - кабель для систем управления и сигнализации, не распространяющий горения, с изоляцией и оболочкой из полимерных композиций, не содержащих галогенов КПЭПнг(А)-FRHF;':`- «Лепшыя тавары Рэспублікі Беларусь» 2017 год НАВІНА ГОДА - кабель для сістэм кіравання і сігналізацыі, які не распаўсюджвае гарэння, з ізаляцыяй і абалонкай з палімерных кампазіцый, якія не змяшчаюць галагенаў КПЭПнг(А)-FRHF;`,
        '- и ранее.':` - і раней.`,

      'Заг Наименование': 'Найменне',
      'Заг Масса': 'Маса',
      'Заг Количество': 'Колькасць',
      'Номинальное сечение жил': 'Намінальнае сячэнне жыў, мм² -',
      'Число жил': 'Лік жыў',
      'Удалить из корзины': 'Выдаліць з кошыка',
      'Цена по запросу': 'Цана па запыце',
      'Оформить заказ': 'Аформіць заказ',
      'Продолжить покупки': 'Продолжить покупки',
      'На данный момент ваша корзина пуста.': 'На дадзены момант ваш кошык пусты.',
      'В каталоге вы можете найти интересующие вас':
        'У каталогу вы можаце знайсці якія цікавяць вас',
      'Перейти в каталог': 'Перайсці ў каталог',
      'allCategories': 'Усе катэгорыі',
      'Политика в отношении обработки файлов cookie': 'Палітыка ў адносінах да апрацоўкі файлаў cookie'
    },
  },
}

console.log(localStorage.getItem('lng'));
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ru: {
        translation: {
          ...resources.ru.translation,
          ...calc.ru.translation,
          ...products.ru.translation,
          ...production.ru.translation,
          ...management.ru.translation,
          ...qualitypolicy.ru.translation,
          ...testing.ru.translation,
          ...innovate.ru.translation,
          ...photogallery.ru.translation,
          ...work.ru.translation,
          ...checkout.ru.translation,
          ...contacts.ru.translation,
          ...cardpage.ru.translation,
          ...schedule.ru.translation,
          ...shareholders.ru.translation,
          ...modal.ru.translation,
          ...leaders.ru.translation,
          ...dillers.ru.translation,
          ...importProd.ru.translation,
        },
      },
      en: {
        translation: {
          ...resources.en.translation,
          ...calc.en.translation,
          ...products.en.translation,
          ...production.en.translation,
          ...management.en.translation,
          ...qualitypolicy.en.translation,
          ...testing.en.translation,
          ...innovate.en.translation,
          ...photogallery.en.translation,
          ...work.en.translation,
          ...checkout.en.translation,
          ...contacts.en.translation,
          ...cardpage.en.translation,
          ...schedule.en.translation,
          ...shareholders.en.translation,
          ...modal.en.translation,
          ...leaders.en.translation,
          ...dillers.en.translation,
          ...importProd.en.translation,
        },
      },
      be: {
        translation: {
          ...resources.bel.translation,
          ...calc.bel.translation,
          ...products.bel.translation,
          ...production.bel.translation,
          ...management.bel.translation,
          ...qualitypolicy.bel.translation,
          ...testing.bel.translation,
          ...innovate.bel.translation,
          ...photogallery.bel.translation,
          ...work.bel.translation,
          ...checkout.bel.translation,
          ...contacts.bel.translation,
          ...cardpage.bel.translation,
          ...schedule.bel.translation,
          ...shareholders.bel.translation,
          ...modal.bel.translation,
          ...leaders.bel.translation,
          ...dillers.bel.translation,
          ...importProd.bel.translation,
        },
      },
    },
    fallbackLng: 'ru',
    lng: localStorage.getItem('lng'),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
